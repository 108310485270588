import React from 'react';
import './calendar.css';
import CalendarIconSmall from '@/icons/TopNav/CalendarIconSmall';

function Calendar({ onClick }) {
	return (
		<button
			type="button"
			onClick={onClick}
			className="select-none hover:shadow-blue-sm rounded-8"
			style={{ width: '5.7rem' }}
		>
			<div
				className="flex flex-col items-center gap-10 rounded-8 px-4 py-6"
				style={{
					color: 'rgb(213, 231, 255)0',
					background:
						'rgb(213, 231, 255) linear-gradient(90deg, rgba(213, 231, 255, 1) 0%, rgba(227, 249, 255, 1) 100%)',
				}}
			>
				<CalendarIconSmall className="text-pd-blue-light" />
				<div className="cal-text">{`${new Date().getDate()}, ${new Date().toString().slice(0, 3)}`}</div>
			</div>
		</button>
	);
}

export default Calendar;
