import * as React from 'react';
const InstallIconChrome = (props) => (
	<svg
		aria-hidden="true"
		className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-dhaba5"
		data-testid="InstallDesktopRoundedIcon"
		viewBox="0 0 24 24"
		{...props}
	>
		<path d="M20 17H4V5h8V3H4c-1.1 0-2 .9-2 2v12a2 2 0 0 0 2 2h4v1c0 .55.45 1 1 1h6c.55 0 1-.45 1-1v-1h4c1.1 0 2-.9 2-2v-3h-2z" />
		<path d="M17.71 13.29 21.3 9.7a.996.996 0 0 0 0-1.41.996.996 0 0 0-1.41 0L18 10.17V4c0-.55-.45-1-1-1s-1 .45-1 1v6.17l-1.89-1.88a.996.996 0 0 0-1.41 0 .996.996 0 0 0 0 1.41l3.59 3.59c.4.39 1.03.39 1.42 0" />
	</svg>
);
export default InstallIconChrome;
