export const defaultHistoryTimes = [
	{
		id: 0,
		start_time: '06:00:00',
		end_time: '07:00:00',
		timings: [
			{ id: 0, start_time: '06:00:00', end_time: '06:20:00' },
			{ id: 1, start_time: '06:20:00', end_time: '06:40:00' },
			{ id: 2, start_time: '06:40:00', end_time: '07:00:00' },
		],
	},
	{
		id: 1,
		start_time: '07:00:00',
		end_time: '08:00:00',
		timings: [
			{ id: 0, start_time: '07:00:00', end_time: '07:20:00' },
			{ id: 1, start_time: '07:20:00', end_time: '07:40:00' },
			{ id: 2, start_time: '07:40:00', end_time: '08:00:00' },
		],
	},
	{
		id: 2,
		start_time: '08:00:00',
		end_time: '09:00:00',
		timings: [
			{ id: 0, start_time: '08:00:00', end_time: '08:20:00' },
			{ id: 1, start_time: '08:20:00', end_time: '08:40:00' },
			{ id: 2, start_time: '08:40:00', end_time: '09:00:00' },
		],
	},
	{
		id: 3,
		start_time: '09:00:00',
		end_time: '10:00:00',
		timings: [
			{ id: 0, start_time: '09:00:00', end_time: '09:20:00' },
			{ id: 1, start_time: '09:20:00', end_time: '09:40:00' },
			{ id: 2, start_time: '09:40:00', end_time: '10:00:00' },
		],
	},
	{
		id: 4,
		start_time: '10:00:00',
		end_time: '11:00:00',
		timings: [
			{ id: 0, start_time: '10:00:00', end_time: '10:20:00' },
			{ id: 1, start_time: '10:20:00', end_time: '10:40:00' },
			{ id: 2, start_time: '10:40:00', end_time: '11:00:00' },
		],
	},
	{
		id: 5,
		start_time: '11:00:00',
		end_time: '12:00:00',
		timings: [
			{ id: 0, start_time: '11:00:00', end_time: '11:20:00' },
			{ id: 1, start_time: '11:20:00', end_time: '11:40:00' },
			{ id: 2, start_time: '11:40:00', end_time: '12:00:00' },
		],
	},
	{
		id: 6,
		start_time: '12:00:00',
		end_time: '13:00:00',
		timings: [
			{ id: 0, start_time: '12:00:00', end_time: '12:20:00' },
			{ id: 1, start_time: '12:20:00', end_time: '12:40:00' },
			{ id: 2, start_time: '12:40:00', end_time: '13:00:00' },
		],
	},
	{
		id: 7,
		start_time: '13:00:00',
		end_time: '14:00:00',
		timings: [
			{ id: 0, start_time: '13:00:00', end_time: '13:20:00' },
			{ id: 1, start_time: '13:20:00', end_time: '13:40:00' },
			{ id: 2, start_time: '13:40:00', end_time: '14:00:00' },
		],
	},
	{
		id: 8,
		start_time: '14:00:00',
		end_time: '15:00:00',
		timings: [
			{ id: 0, start_time: '14:00:00', end_time: '14:20:00' },
			{ id: 1, start_time: '14:20:00', end_time: '14:40:00' },
			{ id: 2, start_time: '14:40:00', end_time: '15:00:00' },
		],
	},
	{
		id: 9,
		start_time: '15:00:00',
		end_time: '16:00:00',
		timings: [
			{ id: 0, start_time: '15:00:00', end_time: '15:20:00' },
			{ id: 1, start_time: '15:20:00', end_time: '15:40:00' },
			{ id: 2, start_time: '15:40:00', end_time: '16:00:00' },
		],
	},
	{
		id: 10,
		start_time: '16:00:00',
		end_time: '17:00:00',
		timings: [
			{ id: 0, start_time: '16:00:00', end_time: '16:20:00' },
			{ id: 1, start_time: '16:20:00', end_time: '16:40:00' },
			{ id: 2, start_time: '16:40:00', end_time: '17:00:00' },
		],
	},
	{
		id: 11,
		start_time: '17:00:00',
		end_time: '18:00:00',
		timings: [
			{ id: 0, start_time: '17:00:00', end_time: '17:20:00' },
			{ id: 1, start_time: '17:20:00', end_time: '17:40:00' },
			{ id: 2, start_time: '17:40:00', end_time: '18:00:00' },
		],
	},
	{
		id: 12,
		start_time: '18:00:00',
		end_time: '19:00:00',
		timings: [
			{ id: 0, start_time: '18:00:00', end_time: '18:20:00' },
			{ id: 1, start_time: '18:20:00', end_time: '18:40:00' },
			{ id: 2, start_time: '18:40:00', end_time: '19:00:00' },
		],
	},
];