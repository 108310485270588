import React from 'react';
import authRoles from '@/app/auth/authRoles';
import Schools from './Schools';
import AddSchools from './AddSchools';
import EditSchools from './EditSchools';

const SchoolsConfig = {
	settings: {
		layout: {
			config: {},
		},
	},
	routes: [
		{
			path: '/schools',
			// component: Schools,
			component: React.lazy(() => import('./Schools')),
		},
		{
			path: '/addschool',
			// component: AddSchools,
			component: React.lazy(() => import('./AddSchools')),
		},
		{
			path: '/editschool/:id',
			// component: EditSchools,
			component: React.lazy(() => import('./EditSchools')),
		},
	],
	auth: authRoles.admin,
};

export default SchoolsConfig;
