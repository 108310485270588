import CustomButton from '@/app/customComponents/CustomButton/CustomButton';
import { Avatar, Dialog, IconButton, makeStyles } from '@material-ui/core';
import { Close, VisibilityOffOutlined, VisibilityOutlined } from '@material-ui/icons';
import { useState } from 'react';
import { BsEnvelope } from 'react-icons/bs';
import ModalBase from '../ModalBase';
import '@/app/main/subadmin/students/profileInfoCard.css';
import history from '@/@history';

const useStyles = makeStyles(() => ({
	backDrop: {
		backdropFilter: 'blur(10px)',
		backgroundColor: 'rgba(0,0,0,0.5)',
	},
}));

const test = `${window.location.origin}/assets/images/profile1.jpg`;

const ProfileModal = ({ selectedProfile, ...props }) => {
	const { childName, profile } = selectedProfile;
	const classes = useStyles();
	const isParent = true; // self.role === 'parent' ? 1 : ''; removing condition as all roles can login to the mobile app
	const canPickup = profile?.can_pickup;
	const [isHidden, setIsHidden] = useState(true);
	const [pictureLarge, setPictureLarge] = useState(false);

	const PictureLarge = () => {
		setPictureLarge(true);
	};
	const navigateToMessaging = () => {
		history.push('/messaging');
	};

	return (
		<ModalBase maxWidth={600} showClose={false} {...props}>
			{pictureLarge && (
				<Dialog
					open={pictureLarge}
					BackdropProps={{
						classes: {
							root: classes.backDrop,
						},
					}}
					onClose={() => {
						setPictureLarge(false);
					}}
				>
					<div className="">
						<div className="absolute right-0 cursor-pointer" onClick={() => setPictureLarge(false)}>
							<Close />
						</div>
						<img alt={profile?.first_name} src={profile?.photo ? profile?.photo : test} />
					</div>
				</Dialog>
			)}
			<div className="flex flex-col gap-16">
				<div className="flex justify-between flex-col items-center gap-16">
					<div className="">
						<Avatar
							
							src={profile?.photo ? profile?.photo : test}
							className="cursor-pointer h-72 w-72"
							onClick={PictureLarge}
						/>
					</div>
					<div className="text-center">
						<h1 className="capitalize m-0">
							{profile?.first_name} {profile?.last_name}
						</h1>
						<p className="capitalize m-0">{profile?.relation_with_child}</p>
					</div>
					<div className=" flex  gap-20 flex-row items-center">
						{isParent && (
							<CustomButton onClick={navigateToMessaging} variant="primary">
								<div className="flex items-center justify-center gap-6">
									<img
										width="14px"
										style={{ display: 'inline' }}
										src="/assets/images/chat-1.png"
										alt="chat"
									/>
									Message
								</div>
							</CustomButton>
						)}
						<a href={`mailto:${profile?.email}`} target="_blank" rel="noreferrer">
							<CustomButton>
								<div className="flex items-center justify-center gap-6">
									<BsEnvelope /> <span>Email</span>
								</div>
							</CustomButton>
						</a>
					</div>
				</div>
				<div className="">
					<div>
						<div className="grid grid-cols-1 sm:grid-cols-2 gap-16 sm:gap-32 ">
							<div className=" flex flex-col gap-16">
								<div>
									<h2 className="text-xs mb-2">Child Name</h2>
									<p className="font-bold capitalize">{childName}</p>
								</div>

								<div className="flex-1 break-words">
									<h2 className="text-xs mb-2">Email Address</h2>
									<p className="font-bold">{profile?.email}</p>
								</div>
							</div>
							<div className="flex flex-col gap-16">
								<div>
									<h2 className="text-xs mb-2">Phone Number</h2>
									<p className="font-bold">{profile?.phone}</p>
								</div>

								<div className="flex-1">
									<h2 className="text-xs mb-4">Can pick up</h2>
									<div className="flex gap-16">
										<div className="flex">
											<div
												className={`text-white rounded-full h-20 w-20 flex items-center border border-pd-blue-light justify-center mr-12 ${
													canPickup ? 'bg-pd-blue-light' : 'bg-white'
												}`}
												style={{ background: canPickup ? '#4DA0EE' : 'white' }}
											>
												<i style={{ fontSize: 10 }} className="fas fa-check" />
											</div>
											<span className="font-bold">Yes</span>
										</div>
										<div className="flex">
											<div
												className={`text-white rounded-full h-20 w-20 flex items-center border border-pd-blue-light justify-center mr-12 ${
													!canPickup ? 'bg-pd-blue-light' : 'bg-white'
												}`}
											>
												<i style={{ fontSize: 10 }} className="fas fa-check mr-2" />
											</div>
											<span className="font-bold">No</span>
										</div>
									</div>
								</div>
							</div>
						</div>

						{isParent && (
							<div className="mt-16 sm:mt-32">
								<h2 className="text-xs">Check-in Code</h2>
								<div className="font-bold flex items-center">
									<p className=" w-36">
										{profile?.role === 'approved_pickups'
											? 'N/A'
											: profile?.parent_schools
											? isHidden
												? profile?.parent_schools[0]?.checkin_code
														?.split('')
														.map(() => '*')
														.join('')
												: profile?.parent_schools[0]?.checkin_code
											: isHidden
											? profile?.checkin_code
													?.split('')
													.map(() => '*')
													.join('')
											: profile?.checkin_code}
									</p>
									{profile?.role === 'approved_pickups' ? (
										''
									) : profile?.parent_schools || profile?.checkin_code ? (
										<IconButton
											onClick={() => setIsHidden(!isHidden)}
											className="h-32 w-32 flex p-0 items-center transform -translate-y-2 justify-center"
										>
											{isHidden ? <VisibilityOffOutlined /> : <VisibilityOutlined />}
										</IconButton>
									) : null}
								</div>
							</div>
						)}
					</div>
				</div>
			</div>
		</ModalBase>
	);
};

export default ProfileModal;
