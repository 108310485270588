import ChromeIcon from '@/icons/PwaModal/ChromeIcon';
import CustomButton from '../CustomButton/CustomButton';
import ModalBase from './ModalBase';
import FirefoxIcon from '@/icons/PwaModal/FirefoxIcon';
import InstallIconChrome from '@/icons/PwaModal/InstallIconChrome';
import EdgeIcon from '@/icons/PwaModal/EdgeIcon';
import ShareIcon from '@/icons/PwaModal/ChromeShareIcon';
import SafariIcon from '@/icons/PwaModal/SafariIcon';
import EdgeInstallIcon from '@/icons/PwaModal/EdgeInstallIcon';
import { DesktopAccessDisabled } from '@material-ui/icons';

const PwaInstructionsModal = (props) => {
	let instructions = '';
	let browserIcon = <></>;

	const instructionsStyle = 'flex flex-col gap-16';
	const userAgent = navigator.userAgent || navigator.vendor || window.opera;

	const isChrome = userAgent.includes('CriOS') || (userAgent.includes('Chrome') && !userAgent.includes('Edg'));
	const isAndroid = userAgent.includes('Android');
	const isIos = /iPad|iPhone|iPod/.test(userAgent) && !window.MSStream;
	const isSafari =
		userAgent.includes('Version/') &&
		userAgent.includes('Safari/') &&
		!userAgent.includes('Chrome') &&
		!userAgent.includes('CriOS') &&
		!userAgent.includes('Edg');
	const isFirefox = userAgent.includes('FxiOS') || (userAgent.includes('Firefox') && !userAgent.includes('FxiOS'));
	const isEdge = userAgent.includes('Edg');

	if (isChrome) {
		browserIcon = <ChromeIcon className="w-32" />;
		if (isAndroid) {
			instructions = (
				<div className={instructionsStyle}>
					<p>1. Tap "Install".</p>
					<p>2. Follow the on-screen instructions.</p>
				</div>
			);
		} else if (isIos) {
			instructions = (
				<div className={instructionsStyle}>
					<p>
						1. Tap on the Share button in the address bar.{' '}
						<span className="ml-6">
							<ShareIcon />
						</span>
					</p>
					<p>2. Tap "Add to Home Screen".</p>
				</div>
			);
		} else {
			instructions = (
				<div className={instructionsStyle}>
					<p>
						1. Click on the "Install" button in the address bar.{' '}
						<span className="ml-6">
							<InstallIconChrome />
						</span>
					</p>
					<p>2. Select "Install" when prompted.</p>
				</div>
			);
		}
	} else if (isFirefox) {
		browserIcon = <FirefoxIcon className="w-32" />;
		if (isAndroid) {
			instructions = (
				<div className={instructionsStyle}>
					<p>1. Tap on the Menu button in the bottom right of your screen.</p>
					<p>2. Select "Share".</p>
					<p>3. Select "Add to Home Screen".</p>
				</div>
			);
		} else if (isIos) {
			instructions = (
				<div className={instructionsStyle}>
					<p>1. Tap on the Menu button in the bottom right of your screen.</p>
					<p>2. Select "Share".</p>
					<p>3. Select "Add to Home Screen".</p>
				</div>
			);
		} else {
			instructions = (
				<div className={instructionsStyle}>
					<p>
						Firefox desktop browser doesn't current's support installing Web Apps. You can download the Web
						App version of this site by:
					</p>
					<p className="flex items-center">
						1. Open Chrome browser <ChromeIcon className="w-24 ml-10 h-24" />
					</p>
					<p>
						2. Click on the "Install" button in the address bar.{' '}
						<span className="ml-6">
							<InstallIconChrome />
						</span>
					</p>
					<p>3. Select "Install" when prompted.</p>
				</div>
			);
		}
	} else if (isSafari) {
		browserIcon = <SafariIcon className="w-32" />;
		instructions = (
			<div className="flex flex-col gap-16">
				<div className={instructionsStyle}>
					<p className="font-bold">iPhone/iPad</p>
					<p>
						1. Tap the Share button in the browser.{' '}
						<span className="ml-6">
							<ShareIcon />
						</span>
					</p>
					<p>2. Tap "Add to Home Screen".</p>
				</div>
				<div className={instructionsStyle}>
					<p className="font-bold">Desktop</p>
					<p>
						Safari desktop browser doesn't currently support installing Web Apps. You can download the Web
						App version of this site by following these steps:
					</p>
					<p className="flex items-center">
						1. Open Chrome browser <ChromeIcon className="w-24 ml-10 h-24" />
					</p>
					<p>
						2. Click on the "Install" button in the address bar.{' '}
						<span className="ml-6">
							<InstallIconChrome />
						</span>
					</p>
					<p>3. Select "Install" when prompted.</p>
				</div>
			</div>
		);

		// browserIcon = <ChromeIcon className="w-32" />;
		// instructions = (
		// 	<div className={instructionsStyle}>
		// 		<p>
		// 			Safari desktop browser doesn't current's support installing Web Apps. You can download the Web App
		// 			version of this site by following these steps:
		// 		</p>
		// 		<p className="flex items-center">1. Open Chrome browser </p>
		// 		<p>
		// 			2. Click on the "Install" button in the address bar.{' '}
		// 			<span className="ml-6">
		// 				<InstallIconChrome />
		// 			</span>
		// 		</p>
		// 		<p>3. Select "Install" when prompted.</p>
		// 	</div>
		// );
	} else if (isEdge) {
		browserIcon = <EdgeIcon className="w-32" />;
		instructions = (
			<div className={instructionsStyle}>
				<p className="flex items-center">
					1. Click on the install icon in the address bar{' '}
					<span className="ml-6">
						<EdgeInstallIcon className="w-20" />
					</span>
				</p>
				<p>2. Select "Install"</p>
			</div>
		);
	} else {
		instructions = 'Check the settings of your browser for an option to add to home screen or install the web app.';
	}

	return (
		<ModalBase {...props}>
			<div className="flex flex-col gap-8">
				<div className="flex items-center gap-16">
					<h2>Install this Web App</h2>
					<div className="max-w-16">{browserIcon}</div>
				</div>

				{instructions}
			</div>
		</ModalBase>
	);
};

export default PwaInstructionsModal;
