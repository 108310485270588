import React, { useRef, useEffect } from 'react';

function CustomControls({
	room,
	playerRef,
	boxRef,
	live,
	handleIphoneFullScreenToggle,
	showControls = true,
	setIsFullscreen,
}) {
	let playbackIcons;
	const playButtonRef = useRef(null);
	const volumeButtonRef = useRef(null);
	const volumeRef = useRef(null);
	const muteRef = useRef(null);
	const volumeHighRef = useRef(null);
	const volumeLowRef = useRef(null);
	const timeElapsedRef = useRef(null);
	const durationRef = useRef(null);
	const progressBarRef = useRef(null);
	const seekRef = useRef(null);
	const seekTooltipRef = useRef(null);
	const fullScreenRef = useRef(null);
	const isIphone = /iPhone|iPod/.test(navigator.userAgent);
	// const isIphone = true;

	useEffect(() => {
		playbackIcons = document.querySelectorAll(`#play-${room?.id} .playback-icons use`);
		let interval;

		const video = playerRef.current;

		if (live && video) {
			interval = setInterval(() => {
				initializeVideo();
			}, 2000);
		}

		playButtonRef.current?.addEventListener('click', togglePlay);
		video?.addEventListener('play', updatePlayButton);
		video?.addEventListener('pause', updatePlayButton);
		video?.addEventListener('volumechange', updateVolumeIcon);
		video?.addEventListener('click', togglePlay);
		volumeRef.current?.addEventListener('input', updateVolume);
		volumeButtonRef.current?.addEventListener('click', toggleMute);

		if (isIphone) {
			fullScreenRef.current.addEventListener('click', handleIphoneFullScreenToggle);
		} else {
			fullScreenRef.current.addEventListener('click', toggleFullScreen);
		}
		boxRef.current?.addEventListener('fullscreenchange', updateFullscreenButton);
		seekRef.current?.addEventListener('mousemove', updateSeekTooltip);
		seekRef.current?.addEventListener('input', skipAhead);
		video?.addEventListener('loadedmetadata', initializeVideo);
		video?.addEventListener('timeupdate', updateTimeElapsed);
		video?.addEventListener('timeupdate', updateProgress);

		return () => {
			// Remove event listeners on unmount
			playButtonRef.current?.removeEventListener('click', togglePlay);
			video?.removeEventListener('play', updatePlayButton);
			video?.removeEventListener('pause', updatePlayButton);
			video?.removeEventListener('volumechange', updateVolumeIcon);
			video?.removeEventListener('click', togglePlay);
			volumeRef.current?.removeEventListener('input', updateVolume);
			volumeButtonRef.current?.removeEventListener('click', toggleMute);
			if (isIphone) {
				fullScreenRef.current.removeEventListener('click', handleIphoneFullScreenToggle);
			} else {
				fullScreenRef.current.removeEventListener('click', toggleFullScreen);
			}
			boxRef.current?.removeEventListener('fullscreenchange', updateFullscreenButton);
			seekRef.current?.removeEventListener('mousemove', updateSeekTooltip);
			seekRef.current?.removeEventListener('input', skipAhead);
			clearInterval(interval);
		};
	}, [room?.id, playerRef.current]);

	// updating this state lets the layout in the HistoryStreaming
	//  component know to to remove the aspect ratio from the video
	//  container so it will fill the screen when not viewing from an iphone
	useEffect(() => {
		const handleFullscreenChange = () => {
			if (setIsFullscreen) {
				if (!document.fullscreenElement) {
					setIsFullscreen(false);
				} else {
					setIsFullscreen(true);
				}
			}
		};

		document.addEventListener('fullscreenchange', handleFullscreenChange);

		return () => {
			document.removeEventListener('fullscreenchange', handleFullscreenChange);
		};
	}, []);

	// togglePlay toggles the playback state of the playerRef.current.
	// If the video playback is paused or ended, the video is played
	// otherwise, the video is paused
	function togglePlay() {
		const video = playerRef.current;
		if (video) {
			if (video.paused || video.ended) {
				video.play();
			} else {
				video.pause();
			}
		}
	}

	// updatePlayButton updates the playback icon and tooltip
	// depending on the playback state
	function updatePlayButton() {
		const video = playerRef.current;
		if (video) {
			playbackIcons.forEach((icon) => icon.classList.toggle('hidden'));

			if (video.paused) {
				playButtonRef.current?.setAttribute('data-title', 'Play (k)');
			} else {
				playButtonRef.current?.setAttribute('data-title', 'Pause (k)');
			}
		}
	}

	// updateVolume updates the video's volume
	// and disables the muted state if active
	function updateVolume() {
		const video = playerRef.current;
		if (video) {
			if (video.muted) {
				video.muted = false;
			}

			video.volume = volumeRef.current?.value;
		}
	}

	// updateVolumeIcon updates the volume icon so that it correctly reflects
	// the volume of the video
	function updateVolumeIcon() {
		const video = playerRef.current;
		if (video) {
			volumeHighRef.current?.classList.add('hidden');
			volumeLowRef.current?.classList.add('hidden');
			muteRef.current?.classList.add('hidden');

			volumeButtonRef.current?.setAttribute('data-title', 'Mute (m)');

			if (video.muted || video.volume === 0) {
				muteRef.current?.classList.remove('hidden');
				volumeButtonRef.current?.setAttribute('data-title', 'Unmute (m)');
			} else if (video.volume > 0 && video.volume <= 0.5) {
				volumeLowRef.current?.classList.remove('hidden');
			} else {
				volumeHighRef.current?.classList.remove('hidden');
			}
		}
	}

	// toggleMute mutes or unmutes the video when executed
	// When the video is unmuted, the volume is returned to the value
	// it was set to before the video was muted
	function toggleMute() {
		const video = playerRef.current;
		if (video) {
			video.muted = !video.muted;

			if (video.muted) {
				volumeRef.current?.setAttribute('data-volume', volumeRef.current?.value);
				volumeRef.current.value = 0;
			} else {
				volumeRef.current.value = volumeRef.current?.dataset.volume;
			}
		}
	}

	// toggleFullScreen toggles the full screen state of the video
	// If the browser is currently in fullscreen mode,
	// then it should exit and vice versa.
	function toggleFullScreen() {
		// If the user is not using an iPhone or iPod, use the standard fullscreen methods
		if (document.fullscreenElement) {
			document.exitFullscreen();
		} else if (document.webkitFullscreenElement) {
			// Need this to support Safari
			document.webkitExitFullscreen();
		} else if (boxRef.current.requestFullscreen) {
			boxRef.current.requestFullscreen();
		} else if (boxRef.current.webkitRequestFullscreen) {
			boxRef.current.webkitRequestFullscreen();
		} else if (boxRef.current.msRequestFullscreen) {
			boxRef.current.msRequestFullscreen();
		}
	}

	// updateFullscreenButton changes the icon of the full screen button
	// and tooltip to reflect the current full screen state of the video
	function updateFullscreenButton() {
		const fullscreenButton = document.getElementById(`fullscreen-button-${room?.id}`);
		if (document.fullscreenElement) {
			fullscreenButton?.setAttribute('data-title', 'Exit full screen (f)');
		} else {
			fullscreenButton?.setAttribute('data-title', 'Full screen (f)');
		}
	}

	// formatTime takes a time length in seconds and returns the time in
	// minutes and seconds
	function formatTime(timeInSeconds) {
		if (!timeInSeconds) {
			return;
		}
		const result = new Date(timeInSeconds * 1000).toISOString().slice(11, 19);

		return {
			minutes: result.slice(3, 5),
			seconds: result.slice(6, 8),
		};
	}

	// initializeVideo sets the video duration, and maximum value of the
	// progressBar
	function initializeVideo() {
		const video = playerRef.current;
		if (video) {
			const videoDuration = Math.round(video.duration);
			if (seekRef.current) {
				seekRef.current.setAttribute('max', videoDuration);
			}
			if (progressBarRef.current) {
				progressBarRef.current.setAttribute('max', videoDuration);
			}
			const time = formatTime(videoDuration);
			if (durationRef.current && time) {
				durationRef.current.innerText = `${time.minutes}:${time.seconds}`;
				durationRef.current.setAttribute('datetime', `${time.minutes}m ${time.seconds}s`);
			}
		}
	}

	// updateTimeElapsed indicates how far through the video
	// the current playback is by updating the timeElapsed element
	function updateTimeElapsed() {
		const video = playerRef.current;
		if (video) {
			const time = formatTime(Math.round(video?.currentTime || 0));
			if (timeElapsedRef.current && time) {
				timeElapsedRef.current.innerText = `${time.minutes}:${time.seconds}`;
				timeElapsedRef.current.setAttribute('datetime', `${time.minutes}m ${time.seconds}s`);
			}
			updateProgress();
		}
	}

	// updateProgress indicates how far through the video
	// the current playback is by updating the progress bar
	function updateProgress() {
		const video = playerRef.current;
		if (video) {
			if (progressBarRef.current) {
				seekRef.current.value = Math.floor(video?.currentTime || 0);
			}
			if (progressBarRef.current) {
				progressBarRef.current.value = Math.floor(video?.currentTime || 0);
			}
		}
	}

	// updateSeekTooltip uses the position of the mouse on the progress bar to
	// roughly work out what point in the video the user will skip to if
	// the progress bar is clicked at that point
	function updateSeekTooltip(event) {
		const skipTo = Math.round(
			(event.offsetX / event.target.clientWidth) * parseInt(event.target.getAttribute('max'), 10)
		);
		if (seekRef.current) {
			seekRef.current.setAttribute('data-seek', skipTo);
		}
		const t = formatTime(skipTo);
		const rect = playerRef.current?.getBoundingClientRect();
		if (seekTooltipRef.current && t && rect) {
			seekTooltipRef.current.textContent = `${t.minutes}:${t.seconds}`;
			seekTooltipRef.current.style.left = `${event.pageX - rect.left}px`;
			seekTooltipRef.current.style.top = `-30px`;
		}
	}

	// skipAhead jumps to a different point in the video when the progress bar
	// is clicked
	function skipAhead(event) {
		const video = playerRef.current;
		const skipTo = event.target.dataset.seek
			? parseFloat(event.target.dataset.seek)
			: parseFloat(event.target.value);

		if (video && !isNaN(skipTo) && isFinite(skipTo)) {
			video.currentTime = skipTo;
			progressBarRef.current.value = skipTo;
			seekRef.current.value = skipTo;
		} else {
			console.error('Invalid time value: ' + skipTo);
		}
	}

	return (
		<div className="video-controls z-99" id={`video-controls-${room?.id}`}>
			<div
				className={`flex flex-col gap-4 px-16 py-16 transition-all w-full transform`}
				style={{
					backgroundImage: 'linear-gradient(to bottom, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.5))',
					transition: 'all .3s ease',
					transform: showControls ? 'translateY(0)' : 'translateY(100%)',
				}}
			>
				<div className="video-progress flex-grow">
					<progress ref={progressBarRef} id={`progress-bar-${room?.id}`} defaultValue={0} min="0"></progress>
					<input
						className="seek"
						ref={seekRef}
						id={`seek-${room?.id}`}
						defaultValue={0}
						min="0"
						type="range"
						step="1"
					/>
					<div className="seek-tooltip" ref={seekTooltipRef} id={`seek-tooltip-${room?.id}`}>
						00:00
					</div>
				</div>

				<div className="bottom-controls gap-4">
					<div className="left-controls">
						<button ref={playButtonRef} data-title="Play (k)" id={`play-${room?.id}`}>
							<svg className="playback-icons svg-video-controls">
								<use href="#play-icon"></use>
								<use className="hidden" href="#pause"></use>
							</svg>
						</button>

						<div className="volume-controls">
							<button
								data-title="Mute (m)"
								className="volume-button"
								ref={volumeButtonRef}
								id={`volume-button-${room?.id}`}
							>
								<svg className="svg-video-controls">
									<use ref={muteRef} href="#volume-mute"></use>
									<use ref={volumeLowRef} className="hidden" href="#volume-low"></use>
									<use ref={volumeHighRef} className="hidden" href="#volume-high"></use>
								</svg>
							</button>

							<input
								className="volume rounded-lg hidden sm:block"
								id={`volume-${room?.id}`}
								ref={volumeRef}
								defaultValue={0}
								data-mute="0.5"
								type="range"
								max="1"
								min="0"
								step="0.01"
							/>
						</div>

						<div className="time text-xs md:text-sm">
							<time ref={timeElapsedRef} id={`time-elapsed-${room?.id}`}>
								00:00
							</time>
							<span> / </span>
							<time ref={durationRef} id="duration">
								00:00
							</time>
						</div>
					</div>
					<div className="right-controls">
						<button
							data-title="Full screen (f)"
							className="fullscreen-button"
							ref={fullScreenRef}
							id={`fullscreen-button-${room?.id}`}
						>
							<svg className="svg-video-controls">
								<use href="#fullscreen"></use>
								<use href="#fullscreen-exit" className="hidden"></use>
							</svg>
						</button>
					</div>
				</div>
			</div>
			<svg style={{ display: 'none' }}>
				<defs>
					<symbol id="pause" viewBox="0 0 24 24">
						<path d="M14.016 5.016h3.984v13.969h-3.984v-13.969zM6 18.984v-13.969h3.984v13.969h-3.984z"></path>
					</symbol>

					<symbol id="play-icon" viewBox="0 0 24 24">
						<path d="M8.016 5.016l10.969 6.984-10.969 6.984v-13.969z"></path>
					</symbol>

					<symbol id="volume-high" viewBox="0 0 24 24">
						<path d="M14.016 3.234q3.047 0.656 5.016 3.117t1.969 5.648-1.969 5.648-5.016 3.117v-2.063q2.203-0.656 3.586-2.484t1.383-4.219-1.383-4.219-3.586-2.484v-2.063zM16.5 12q0 2.813-2.484 4.031v-8.063q1.031 0.516 1.758 1.688t0.727 2.344zM3 9h3.984l5.016-5.016v16.031l-5.016-5.016h-3.984v-6z"></path>
					</symbol>

					<symbol id="volume-low" viewBox="0 0 24 24">
						<path d="M5.016 9h3.984l5.016-5.016v16.031l-5.016-5.016h-3.984v-6zM18.516 12q0 2.766-2.531 4.031v-8.063q1.031 0.516 1.781 1.711t0.75 2.32z"></path>
					</symbol>

					<symbol id="volume-mute" viewBox="0 0 24 24">
						<path d="M12 3.984v4.219l-2.109-2.109zM4.266 3l16.734 16.734-1.266 1.266-2.063-2.063q-1.547 1.313-3.656 1.828v-2.063q1.172-0.328 2.25-1.172l-4.266-4.266v6.75l-5.016-5.016h-3.984v-6h4.734l-4.734-4.734zM18.984 12q0-2.391-1.383-4.219t-3.586-2.484v-2.063q3.047 0.656 5.016 3.117t1.969 5.648q0 2.203-1.031 4.172l-1.5-1.547q0.516-1.266 0.516-2.625zM16.5 12q0 0.422-0.047 0.609l-2.438-2.438v-2.203q1.031 0.516 1.758 1.688t0.727 2.344z"></path>
					</symbol>

					<symbol id="fullscreen" viewBox="0 0 24 24">
						<path d="M14.016 5.016h4.969v4.969h-1.969v-3h-3v-1.969zM17.016 17.016v-3h1.969v4.969h-4.969v-1.969h3zM5.016 9.984v-4.969h4.969v1.969h-3v3h-1.969zM6.984 14.016v3h3v1.969h-4.969v-4.969h1.969z"></path>
					</symbol>

					<symbol id="fullscreen-exit" viewBox="0 0 24 24">
						<path d="M15.984 8.016h3v1.969h-4.969v-4.969h1.969v3zM14.016 18.984v-4.969h4.969v1.969h-3v3h-1.969zM8.016 8.016v-3h1.969v4.969h-4.969v-1.969h3zM5.016 15.984v-1.969h4.969v4.969h-1.969v-3h-3z"></path>
					</symbol>
				</defs>
			</svg>
		</div>
	);
}

export default CustomControls;
