import React from 'react';
import authRoles from '../../../auth/authRoles';
import Messaging from './Messaging';

import MessagingThread from './messaging/messagingThread';
import messagingListing from './messaging/messagingListing';

const MessagingConfig = {
	settings: {
		layout: {
			config: {},
		},
	},
	routes: [
		{
			path: '/messaging',
			// component: Messaging
			component: React.lazy(() => import('./Messaging')),
		},
		{
			path: '/messagingListing',
			// component: messagingListing,
			component: React.lazy(() => import('./messaging/messagingListing')),
		},
		{
			path: '/messaging-chat',
			// component: MessagingThread,
			component: React.lazy(() => import('./messaging/messagingThread')),
		},
	],
	auth: authRoles.subadmin,
};

export default MessagingConfig;
