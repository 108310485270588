import React from 'react';
import authRoles from '../../../auth/authRoles';
import AddParent from './AddParent';
import EnrollStudents from './EnrollStudents';
import Students from './Students';
import SubmitRoster from './SubmitRoster';

const StudentsConfig = {
	settings: {
		layout: {
			config: {},
		},
	},
	routes: [
		{
			path: '/students',
			// component: Students
			component: React.lazy(() => import('./Students')),
		},
		{
			path: '/students-enroll',
			// component: EnrollStudents
			component: React.lazy(() => import('./EnrollStudents')),
		},
		{
			path: '/students-addparent',
			// component: AddParent
			component: React.lazy(() => import('./AddParent')),
		},
		{
			path: '/students-submitroster',
			// component: SubmitRoster
			component: React.lazy(() => import('./SubmitRoster')),
		},
	],
	auth: authRoles.subadmin,
};

export default StudentsConfig;
