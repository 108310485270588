import React from 'react';
import Drawer from '@material-ui/core/Drawer';
import { useState } from 'react';
import { checkIn } from '@/app/services/students/students';
import { CircularProgress, IconButton } from '@material-ui/core';
import './rooms.css';
import { useDispatch } from 'react-redux';
import * as Actions from '@/app/store/actions';
import CustomButton from '@/app/customComponents/CustomButton/CustomButton';
import PickupCard from './PickupCard';
import { Close } from '@material-ui/icons';

export default function CheckoutDrawer({ roomId, setActiveId, stuData, open, setOpen, isLoading, fixed, setRefresh }) {
	const [pending, isPending] = useState(false);

	const dispatch = useDispatch();
	const checkInGeneric = () => {
		const data = {
			student_id: [stuData.id],
			room_id: roomId,
			family_id: 0,
			is_parent: 0,
		};
		isPending(true);
		checkIn(data)
			.then((res) => {
				setActiveId(0);
				setRefresh(true);
				setOpen(false);
				dispatch(
					Actions.showMessage({
						variant: 'success',
						message: res.data.message,
					})
				);
			})
			.catch((err) => {
				dispatch(
					Actions.showMessage({
						variant: 'error',
						message: 'Failed to update student status',
					})
				);
			})
			.finally(() => isPending(false));
	};

	const closeDrawer = () => {
		setOpen(false);
		setActiveId(null);
	};

	return (
		<div className="shadow-lg">
			<Drawer
				anchor={'right'}
				elevation={16}
				variant={fixed ? 'persistent' : 'temporary'}
				open={open}
				onClose={closeDrawer}
			>
				<div>
					<div
						className={`p-20   min-h-screen w-288  ${fixed ? 'mt-80' : ''}`}
						style={{
							background:
								'linear-gradient(130deg, rgba(244, 249, 255, 1) 0%, rgba(208, 226, 244, 1) 100%)',
						}}
					>
						<IconButton
							className="h-32 p-0 m-0 w-32 flex items-center justify-center absolute top-16 left-8"
							onClick={closeDrawer}
						>
							<Close className="p-0" />
						</IconButton>
						{isLoading ? (
							<div className="absolute inset-0 flex items-center justify-center">
								<CircularProgress size={35} />
							</div>
						) : (
							<div className="">
								<div className={`flex justify-end absolute right-10  ${fixed ? '' : 'top-16'}`}>
									{stuData.checkin_status === 'checkin' ? (
										<></>
									) : (
										<>
											{!isLoading ? (
												<CustomButton onClick={checkInGeneric}>Check In</CustomButton>
											) : (
												<CircularProgress size={25} />
											)}
										</>
									)}
								</div>
								{stuData?.parents ? (
									<div className="flex justify-between items-center mb-28 pr-16 pt-40">
										<h4 className=" font-extrabold" style={{ fontSize: '18px' }}>
											Parents
										</h4>
									</div>
								) : (
									<></>
								)}

								{stuData?.parents?.map((parent) => (
									<PickupCard
										key={parent.id}
										pickup={parent}
										checkedIn={stuData.checkin_status === 'checkin'}
										studentId={stuData.id}
										roomId={roomId}
										setActiveId={setActiveId}
										isLoading={pending}
										setIsLoading={isPending}
										setRefresh={setRefresh}
										setOpen={setOpen}
									/>
								))}
								{stuData?.extended_family?.length ? (
									<h4 className="mb-28 font-extrabold" style={{ fontSize: '18px' }}>
										Family
									</h4>
								) : (
									''
								)}
								{stuData?.extended_family?.map((pickup) => {
									return (
										<PickupCard
											key={pickup.id}
											pickup={pickup}
											checkedIn={stuData.checkin_status === 'checkin'}
											studentId={stuData.id}
											roomId={roomId}
											setActiveId={setActiveId}
											isLoading={pending}
											setIsLoading={isPending}
											setRefresh={setRefresh}
											setOpen={setOpen}
										/>
									);
								})}
								{stuData?.approved_pickups?.length ? (
									<h4 className="mb-28 font-extrabold" style={{ fontSize: '18px' }}>
										Approved Pickups
									</h4>
								) : (
									''
								)}
								{stuData?.approved_pickups?.map((pickup) => {
									return (
										<PickupCard
											key={pickup.id}
											pickup={pickup}
											checkedIn={stuData.checkin_status === 'checkin'}
											studentId={stuData.id}
											roomId={roomId}
											setActiveId={setActiveId}
											isLoading={pending}
											setIsLoading={isPending}
											setRefresh={setRefresh}
											setOpen={setOpen}
										/>
									);
								})}
							</div>
						)}
					</div>
				</div>
			</Drawer>
		</div>
	);
}
