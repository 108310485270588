import React, { useEffect, useState } from 'react';
import { CircularProgress, IconButton, TextField, InputAdornment, Avatar } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import InfiniteScroll from 'react-infinite-scroll-component';
import './rooms.css';
import { getRoom } from '@/app/services/rooms/rooms';
import { useDispatch } from 'react-redux';
import * as Actions from '@/app/store/actions';
import history from '@/@history';
import { useParams } from 'react-router';
import PageHeader from '@/app/customComponents/PageHeader';

function StudentTable(props) {
	const dispatch = useDispatch();
	const [searchQuery, setSearchQuery] = useState('');
	const [isLoading, setIsLoading] = useState(false);
	const [rows, setRows] = useState([]);
	const [firstLoad, setFirstLoad] = useState(true);
	const [fetchingMore, setFetchingMore] = useState(false);
	const [hasMore, setHasMore] = useState(false);
	const [page, setPage] = useState(1);
	const [row, setRow] = useState(props.row);
	const { activeId, setActiveId, setDrawerOpen, refresh, setRefresh } = props;
	const { id } = useParams();

	const handleSearch = (e) => {
		setSearchQuery(e.target.value);
	};

	const handleIdChange = (selectedId) => {
		if (selectedId === activeId) {
			setActiveId(null);
			setDrawerOpen(false);
		} else {
			setDrawerOpen(true);
			setActiveId(selectedId);
		}
	};

	const handleLoadMore = () => {
		if (!row.id) {
			history.push('/checkinout');
		}
		setFetchingMore(true);
		getRoom(searchQuery, page, id)
			.then((res) => {
				if (res.data.last_page > res.data.current_page) {
					setHasMore(true);
				} else {
					setHasMore(false);
				}
				setPage(res.data.current_page + 1);
				setRows(rows.concat(res.data.students));
				setFetchingMore(false);
			})
			.catch((err) => {
				setFetchingMore(false);
				dispatch(
					Actions.showMessage({
						message: 'Failed to fetch data, please refresh',
						variant: 'error',
					})
				);
			});
	};

	const fetchData = () => {
		setIsLoading(true);
		setFirstLoad(false);
		getRoom(searchQuery, 1, id)
			.then((res) => {
				if (res.data.last_page > res.data.current_page) {
					setHasMore(true);
				} else {
					setHasMore(false);
				}
				setPage(res.data.current_page + 1);
				setRows(res.data.students.filter((child) => child.status === 1));
				setRow(res.data);
			})
			.catch((err) => {
				dispatch(
					Actions.showMessage({
						message: 'Failed to fetch data, please refresh',
						variant: 'error',
					})
				);
			})
			.finally(() => {
				setRefresh(false);
				setIsLoading(false);
			});
	};

	useEffect(() => {
		let isMounted = true;
		if (!firstLoad && !refresh && !searchQuery) return;
		if (isMounted) fetchData();

		return () => {
			isMounted = false;
		};
		// eslint-disable-next-line
	}, [dispatch, id, refresh]);

	useEffect(() => {
		let isMounted = true;
		const timerId = setTimeout(() => {
			if (firstLoad) return;
			if (isMounted) fetchData();
		}, 500);

		return () => {
			isMounted = false;
			clearTimeout(timerId);
		};
		// eslint-disable-next-line
	}, [searchQuery]);

	const handleGoBack = () => {
		history.goBack();
	};

	return (
		<>
			<div className="mt-16 md:mt-32 w-11/12 max-w-3xl mx-auto overflow-hidden">
				<div className="flex justify-between flex-col sm:flex-row">
					<PageHeader title={row.name} onBack={handleGoBack} />

					<TextField
						className=" sm:max-w-400"
						fullWidth
						onChange={handleSearch}
						id="search"
						value={searchQuery}
						label="Search"
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									<IconButton
										onClick={() => {
											document.getElementById('search').focus();
										}}
									>
										<img
											alt="search-icon"
											src="assets/images/search-icon.svg"
											height="80%"
											width="80%"
										/>
									</IconButton>
								</InputAdornment>
							),
						}}
					/>
				</div>

				<TableContainer
					id="Scrollable-table"
					component={Paper}
					className="rooms-table-cont rounded-8 shadow-card"
				>
					<Table stickyHeader className="rooms-table" style={{ width: '100%' }}>
						<TableHead>
							<TableRow>
								<TableCell className="bg-white checkinout-table-header" style={{ width: '26%' }}>
									Name
								</TableCell>
								<TableCell
									align="center"
									className="bg-white checkinout-table-header hidden sm:table-cell"
									style={{ width: '19%' }}
								>
									Age
								</TableCell>
								<TableCell
									className="bg-white checkinout-table-header hidden md:table-cell"
									style={{ width: '19%' }}
								>
									Date of Birth
								</TableCell>
								<TableCell
									align="center"
									className="bg-white checkinout-table-header hidden md:table-cell"
									style={{ width: '18%' }}
								>
									Gender
								</TableCell>
								<TableCell
									align="right"
									className="bg-white checkinout-table-header "
									style={{ width: '18%' }}
								>
									Status
								</TableCell>
							</TableRow>
						</TableHead>
						<TableBody className="">
							{isLoading ? (
								<TableRow>
									<TableCell align="center" colSpan={8}>
										<CircularProgress size={35} />
									</TableCell>
								</TableRow>
							) : !rows.length && !firstLoad ? (
								<TableRow>
									<TableCell align="center" colSpan={8}>
										No Students
									</TableCell>
								</TableRow>
							) : (
								rows?.map((student) => (
									<TableRow
										style={{ cursor: 'pointer' }}
										key={student.id}
										onClick={() => handleIdChange(student.id)}
										className={activeId === student.id ? 'active-student-row' : ''}
									>
										<TableCell style={{ fontWeight: 700 }} scope="row">
											<div className="flex items-center">
												<Avatar className="mr-16" src={student.photo} />
												<div className="room-student-name">{`${student.first_name} ${student.last_name}`}</div>
											</div>
										</TableCell>
										<TableCell
											align="center"
											className="hidden sm:table-cell"
											style={{ fontWeight: 700 }}
										>
											{student.date_of_birth
												? new Date(new Date() - new Date(student.date_of_birth)).getFullYear() -
												  1970
												: '-'}{' '}
											years old
										</TableCell>
										<TableCell className="hidden md:table-cell" style={{ fontWeight: 700 }}>
											{student.date_of_birth
												? new Date(student.date_of_birth)
														.toDateString()
														.split(' ')
														.slice(1)
														.join(' ')
												: '-'}
										</TableCell>
										<TableCell
											align="center"
											className="hidden md:table-cell"
											style={{ fontWeight: 700 }}
										>
											{student.gender}
										</TableCell>
										<TableCell
											align="right"
											style={{
												fontWeight: 700,
												color: student.checkin_status === 'checkout' ? 'red' : '#04C01C',
											}}
										>
											{student.checkin_status === 'checkout' ? 'Checked Out' : 'Checked In'}
										</TableCell>
									</TableRow>
								))
							)}
							{fetchingMore ? (
								<TableRow>
									<TableCell align="center" colSpan={8}>
										<CircularProgress size={35} />
									</TableCell>
								</TableRow>
							) : (
								<></>
							)}
						</TableBody>
					</Table>
				</TableContainer>
				<InfiniteScroll
					dataLength={rows.length}
					next={handleLoadMore}
					hasMore={hasMore}
					scrollableTarget="Scrollable-table"
				/>
			</div>
		</>
	);
}

export default StudentTable;
