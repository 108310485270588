import * as React from 'react';
const CalendarIconSmall = (props) => (
	<svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} 
    viewBox='0 0 207 230'
    fill="none" {...props}>
		<g stroke="currentColor" strokeWidth={15} clipPath="url(#calendarIconSmall)">
			<path
				strokeLinecap="round"
				d="M60.132 8.118v43.294M146.868 100.118H60.132M146.868 132.588H60.132M146.868 165.059H60.132M157.711 8.118v43.294"
			/>
			<path d="M152.289 31.93H54.711c-25.749 0-46.622 20.837-46.622 46.54v97.412c0 25.704 20.873 46.542 46.622 46.542h97.578c25.749 0 46.622-20.838 46.622-46.542V78.471c0-25.704-20.873-46.542-46.622-46.542Z" />
		</g>
		<defs>
			<clipPath id="calendarIconSmall">
				<path fill="#000" d="M.5 0h206v230H.5z" />
			</clipPath>
		</defs>
	</svg>
);
export default CalendarIconSmall;
