import React, { useState } from 'react';

import CustomButton from '@/app/customComponents/CustomButton/CustomButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { CircularProgress } from '@material-ui/core';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import { updateStudent } from '@/app/services/students/students';

function Notes({ student, setRefresh, isLoading }) {
	const [notes, setNotes] = useState({
		student_exempt: student?.student_exempt,
		notes: student?.notes || '',
	});
	const [isSaving, setIsSaving] = useState(false);
	const [isEditing, setIsEditing] = useState(false);

	return (
		<>
			<div className="flex items-center flex-nowrap justify-between mx-auto">
				<span className="self-end">
					<h3 className="flex justify-between md:text-lg lg:text-xl self-end font-extrabold">Notes</h3>
				</span>
				{isSaving && (
					<div className="flex align-center justify-center">
						<CircularProgress size={35} />
					</div>
				)}
				{isEditing && !isSaving ? (
					<div className="btnss">
						<span>
							<CustomButton
								variant="secondary"
								onClick={() => {
									setIsEditing(false);
									setNotes({
										student_exempt: student.student_exempt,
										notes: student.notes,
									});
								}}
							>
								Cancel
							</CustomButton>
						</span>
						<span className="mr-4">
							<CustomButton
								variant="primary"
								onClick={() => {
									const form = { ...student, ...notes };
									setIsSaving(true);
									if (!form.notes) {
										delete form.notes;
									}
									updateStudent(student.id, {
										...form,
										is_medical_condition: form.is_medical_condition || '',
										allergies: student.allergies || '',
										medications: student.medications || '',
										address1: student.address1 || '',
										address2: student.address2 || '',
										zip_code: student.zip_code || 0,
									})
										.then((_res) => {
											setIsSaving(false);
											setIsEditing(false);
											setRefresh(refresh + 1);
										})
										.catch((_err) => setIsSaving(false));
								}}
							>
								Save
							</CustomButton>
						</span>
					</div>
				) : (
					<></>
				)}
				<div className="personal-button flex justify-between">
					{!isEditing ? (
						<CustomButton
							variant="secondary"
							disabled={isLoading}
							onClick={() => {
								setIsEditing(true);
							}}
						>
							<span>
								{' '}
								<FontAwesomeIcon icon={faEdit} />{' '}
							</span>
							<span> Edit </span>
						</CustomButton>
					) : (
						''
					)}
				</div>
			</div>
			<div className="recomd notesrecord p-16 sm:p-32 md:p-64">
				{isLoading ? (
					<div className="flex align-center justify-center">
						<CircularProgress size={35} />
					</div>
				) : (
					<div>
						<h4 className="font-bold text-lg">Student Exempt</h4>
						<h6 className="font-bold text-base">{student?.student_exempt ? 'Yes' : 'No'}</h6>
						<br />
						<h4>Notes</h4>
						{!isEditing ? (
							<TextareaAutosize
								disabled
								style={{ height: 45 }}
								onChange={(e) => setNotes({ ...notes, notes: e.target.value })}
								value={
									notes.notes ||
									`${student?.first_name} ${student?.last_name} has no notes on record.`
								}
							/>
						) : (
							<TextareaAutosize
								className="notes-textarea border-2 border-gray-100 p-6"
								disabled={isSaving}
								style={{ height: 49, lineheight: 19 }}
								onChange={(e) => setNotes({ ...notes, notes: e.target.value })}
								value={notes.notes || ''}
							/>
						)}
					</div>
				)}
			</div>
		</>
	);
}

export default Notes;
