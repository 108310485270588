/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/media-has-caption */
import React, { useState, useEffect, useRef } from 'react';
import Hls from 'hls.js';
import { Avatar, Hidden, Portal } from '@material-ui/core';
import './index.css';
import { getRooms } from '@/app/services/liveStreaming/liveStreaming';
import config from './hlsconfig';
import CustomControls from './CustomControls';
import { Close } from '@material-ui/icons';

function Streaming({ room, setSearch }) {
	const [videoBannerText, setVideoBannerText] = useState('Loading');
	const [hlsNotSupported, setHlsNotSupported] = useState(false);
	const [isFullScreenIphone, setIsFullScreenIphone] = useState(false);
	const [showPortraitOverlay, setShowPortraitOverlay] = useState(true);
	const [showControls, setShowControls] = useState(false);
	const hls = useRef(null);
	const playerRef = useRef(null);
	const boxRef = useRef(null);

	let currentPlaybackPosition = 0;
	const timeouts = [];

	const apiReCall = async () => {
		try {
			const res = await getRooms(room.name, '', 1);
			return res.data.data[0];
		} catch (err) {
			console.log(err);
		}
	};

	const clearTimeouts = () => {
		timeouts.forEach((timeoutId) => {
			clearTimeout(timeoutId);
		});
	};

	const handleBuffering = () => {
		setVideoBannerText('Connecting');
		timeouts.push(
			setTimeout(() => {
				clearTimeouts();
				if (hls && room?.stream_url?.session_url) {
					hls.current.attachMedia(playerRef.current);
					hls.current.loadSource(room?.stream_url?.session_url);

					hls.current.on(Hls.Events.MEDIA_ATTACHED, () => {
						hls.current.on(Hls.Events.MANIFEST_PARSED, () => {
							hls.current.startLoad();
						});
					});
				}
			}, 2500)
		);
	};

	let hoverTimeout;

	const attachEventListeners = () => {
		playerRef.current.addEventListener('waiting', handleBuffering);
		playerRef.current.addEventListener('playing', () => {
			clearTimeouts();
			setVideoBannerText('Live');
		});
		playerRef.current.addEventListener('pause', () => {});

		boxRef.current.addEventListener('mouseenter', () => {
			clearTimeout(hoverTimeout);
			setShowControls(true);
		});
		boxRef.current.addEventListener('mouseleave', () => {
			hoverTimeout = setTimeout(() => {
				setShowControls(false);
			}, 2500); 
		});


		boxRef.current.addEventListener('touchstart', () => {
			clearTimeout(hoverTimeout);
			setShowControls(true);
		});
		boxRef.current.addEventListener('touchend', () => {
			hoverTimeout = setTimeout(() => {
				setShowControls(false);
			}, 2000);
		});
	};

	useEffect(() => {
		return () => {
			clearTimeout(hoverTimeout);
		};
	}, []);

	useEffect(() => {
		let roomLoading = '';
		let retryNetworkCount = 0;

		const initializeHls = () => {
			if (hls.current && room?.stream_url?.session_url) {
				hls.current.loadSource(room?.stream_url?.session_url);

				hls.current.attachMedia(playerRef.current);

				hls.current.on(Hls.Events.MEDIA_ATTACHED, () => {
					hls.current.on(Hls.Events.ERROR, handleHlsError);
					hls.current.on(Hls.Events.MANIFEST_PARSED, () => {
						hls.current.startLoad();
					});
				});

				hls.current.on(Hls.Events.MANIFEST_LOADED, () => {
					playerRef.current.play().catch((error) => {});
				});
			}
		};

		const handleFragmentLoadError = async () => {
			const currentLevel = hls.current.currentLevel;
			const currentFragment = hls.current.mediaBuffer ? hls.currentmediaBuffer : hls.current.media;

			if (currentLevel !== null && currentFragment !== null) {
				hls.current.nextLoadPosition = hls.current.config.startPosition;
				if (roomLoading !== room.name) {
					roomLoading = room.name;
					const roomData = await apiReCall();
					setTimeout(() => {
						clearTimeouts();
						if (hls.current && roomData?.stream_url?.session_url) {
							hls.current.loadSource(roomData?.stream_url?.session_url);
							hls.current.attachMedia(playerRef.current);
							hls.current.on(Hls.Events.MEDIA_ATTACHED, () => {
								playerRef.current.currentTime = currentPlaybackPosition;
								hls.current.startLoad();
							});
						}
						roomLoading = '';
						retryNetworkCount = 0;
					}, config.fragLoadingRetryDelay);
				}
			}
		};

		const handleHlsError = (event, data) => {
			if (data.fatal) {
				switch (data.type) {
					case Hls.ErrorTypes.NETWORK_ERROR:
						retryNetworkCount++;
						handleFragmentLoadError();
						break;
					case Hls.ErrorTypes.MEDIA_ERROR:
						handleFragmentLoadError();
						break;
					default:
						handleFragmentLoadError();
						break;
				}
			}
		};

		if (room?.stream_url?.session_url) {
			if (Hls.isSupported()) {
				hls.current = new Hls(config);
				initializeHls();
				attachEventListeners();
			} else if (playerRef.current.canPlayType('application/vnd.apple.mpegurl')) {
				setHlsNotSupported(true);
				playerRef.current.src = room?.stream_url?.session_url;
			}
		}

		return () => {
			clearTimeouts();

			if (hls.current) {
				hls.current.destroy();
				hls.current = null;
			}
			if (playerRef.current) {
				playerRef.current.removeEventListener('waiting', handleBuffering);
				playerRef.current.removeEventListener('playing', () => {
					clearTimeouts();
					setVideoBannerText('Live');
				});
				playerRef.current.removeEventListener('pause', () => {});
			}
		};
	}, [room?.stream_url?.session_url]);

	const isLive = () => videoBannerText === 'Live';

	const handleIphoneFullScreenToggle = () => {
		setIsFullScreenIphone(!isFullScreenIphone);
		setVideoBannerText('Connecting');

		if (hls.current) {
			hls.current.stopLoad();
			hls.current.destroy();
			hls.current = null;
		}

		if (Hls.isSupported()) {
			hls.current = new Hls(config);
			hls.current.loadSource(room?.stream_url?.session_url);
			hls.current.attachMedia(playerRef.current);
			hls.current.on(Hls.Events.MEDIA_ATTACHED, () => {
				hls.current.on(Hls.Events.MANIFEST_PARSED, () => {
					setTimeout(() => {
						hls.current.startLoad();
					}, 1000); // Give the stream a second to die before restarting it
				});
			});
			attachEventListeners();
		}
	};

	return (
		<div className="relative">
			<div
				className={`mx-auto absolute top-0 font-bold flex items-center gap-8 text-white py-8 z-99 ${
					hlsNotSupported ? '' : 'ml-10'
				}`}
				style={hlsNotSupported ? { left: '50%', transform: 'translateX(-50%)' } : {}}
			>
				<Avatar src={room?.thumb} />
				<div>{room?.name}</div>
			</div>
			<div className="flex justify-center items-center aspect-ratio-16-9 relative">
				{!isFullScreenIphone && (
					<div
						ref={boxRef}
						id={`video-container-${room?.id}`}
						className="w-full video-player-box rounded-lg overflow-hidden h-0 relative"
					>
						<video
							preload="true"
							// use default controls for hls not supported
							controls={hlsNotSupported}
							id={room?.id}
							ref={playerRef}
							autoPlay
							muted
							playsInline
							className="rounded-lg video-player object-fill"
							controlsList="nodownload noplaybackrate"
						></video>

						{room?.stream_url?.overlay_image &&
							room?.stream_url?.session_url && (
								<img
									id="overlayImage"
									className="img-overlay-blur"
									src={room?.stream_url?.overlay_image}
									style={{
										position: 'absolute',
										top: 0,
										left: 0,
										zIndex: 50,
									}}
									height={boxRef.current?.clientHeight}
									width={boxRef.current?.clientWidth}
								/>
							)}
						{room?.stream_url?.session_url && !hlsNotSupported ? (
							<>
								<div className={`btn_live ${isLive() ? '' : 'not_btn_live'} z-50`}>
									{videoBannerText || 'Live'}
									<span className={`${isLive() ? 'live-icon' : 'not-live-icon'}`} />
								</div>
								<CustomControls
									room={room}
									playerRef={playerRef}
									boxRef={boxRef}
									live={true}
									hlsNotSupported={hlsNotSupported}
									handleIphoneFullScreenToggle={handleIphoneFullScreenToggle}
									showControls={showControls}
								/>
							</>
						) : (
							''
						)}
						{!room?.stream_url?.session_url ? (
							<div className="stream-not-running-text">Live Streaming Is Not Available</div>
						) : (
							''
						)}
					</div>
				)}
			</div>
			{isFullScreenIphone && (
				<Portal container={document.body}>
					<div
						className="flex items-center justify-center top-0 left-0 h-full w-full object-fill fixed z-999 bg-black"
						ref={boxRef}
					>
						{showPortraitOverlay && (
							<div
								className=" fixed sm:hidden h-screen w-screen z-999 flex items-center justify-center"
								style={{ background: 'rgba(0, 0, 0, .75)' }}
							>
								<button
									className="p-4 absolute top-72 right-16 text-white"
									onClick={() => setShowPortraitOverlay(false)}
								>
									<Close />
								</button>
								<p className="text-white">
									For the best viewing experience, please rotate your device.
								</p>
							</div>
						)}
						<div className="relative w-full h-full flex items-center justify-center">
							<div className="w-full h-full relative bg-black">
								<video
									preload="true"
									controls={hlsNotSupported}
									id={`${room?.id}`}
									ref={playerRef}
									autoPlay
									muted
									playsInline
									className="w-full h-full object-fill"
									controlsList="nodownload noplaybackrate"
								></video>
								{room?.stream_url?.overlay_image && (
									<img
										src={room?.stream_url?.overlay_image}
										className=" absolute top-0 left-0 z-99 w-full h-full object-fill"
									/>
								)}
								<div className="flex items-center w-full h-48 absolute top-16 pl-16">
									<div
										className={`absolute top-0 font-bold flex items-center gap-8 text-white  z-99 ${
											hlsNotSupported ? '' : 'ml-10'
										}`}
										style={hlsNotSupported ? { left: '50%', transform: 'translateX(-50%)' } : {}}
									>
										<Avatar src={room?.thumb} />
										<div>{room?.name}</div>
									</div>
									<div className={`btn_live ${isLive() ? '' : 'not_btn_live'} z-20`}>
										{videoBannerText || 'Live'}
										<span className={`${isLive() ? 'live-icon' : 'not-live-icon'}`} />
									</div>
								</div>
								<div className="">
									<CustomControls
										room={room}
										playerRef={playerRef}
										boxRef={boxRef}
										live={true}
										hlsNotSupported={hlsNotSupported}
										handleIphoneFullScreenToggle={handleIphoneFullScreenToggle}
										showControls={showControls}
									/>
								</div>
							</div>
						</div>
					</div>
				</Portal>
			)}
		</div>
	);
}

export default Streaming;
