import React from 'react';
import BackButton from './BackButton/BackButton';
import { Typography } from '@material-ui/core';

const PageHeader = ({ hasBack = true, title, onBack }) => {
	return (
		<div className="flex items-center gap-6">
			{hasBack && <BackButton onClick={onBack} />}
			<Typography variant="subtitle2" style={{ fontSize: '20px', display: 'inlineblock' }}>
				{title}
			</Typography>
		</div>
	);
};

export default PageHeader;
