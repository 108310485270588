import React from 'react';
import { Redirect } from 'react-router-dom';
import FuseUtils from '@/@fuse/utils';
import SuperAdminConfig from '@/app/main/superadmin/superAdminConfig';
import pagesConfigs from '@/app/main/pages/pagesConfigs';
import subAdminConfig from '@/app/main/subadmin/subAdminConfig';
// import UserMenuConfig from '@/app/fuse-layouts/shared-components/UserMenuConfig';
import secureLocalStorage from 'react-secure-storage';

const routeConfigs = [...pagesConfigs, ...subAdminConfig, ...SuperAdminConfig];

/** The `routes` constant is an array that defines the routes for a React application. It includes both
dynamically generated routes from `routeConfigs` and two additional routes. */
const routes = [
	...FuseUtils.generateRoutesFromConfigs(routeConfigs),
	/** This code block defines a route for the root path ("/") of the application. It specifies that the
route should match exactly (exact: true). The component for this route is a function that checks the
user's role stored in the secure local storage. */
	{
		path: '/',
		exact: true,
		component: () => {
			if (JSON.parse(secureLocalStorage.getItem('user'))?.role[0] === 'super_admin') {
				return <Redirect to="/company" />;
			}
			if (
				JSON.parse(secureLocalStorage.getItem('user'))?.role[0] === 'super_school_admin' ||
				JSON.parse(secureLocalStorage.getItem('user'))?.role[0] === 'school_admin' ||
				JSON.parse(secureLocalStorage.getItem('user'))?.role[0] === 'sub_admin'
			) {
				return <Redirect to="/home" />;
			}
			return <Redirect to="/login" />;
		},
	},
	{
		path: '/*',
		component: () => <Redirect to="/404" />,
	},
];

export default routes;
