import * as React from 'react';
const ChromeShareIcon = (props) => (
	<svg
		aria-hidden="true"
		className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-dhaba5"
		data-testid="IosShareRoundedIcon"
		viewBox="0 0 24 24"
		{...props}
	>
		<path d="M18 8h-2c-.55 0-1 .45-1 1s.45 1 1 1h2v11H6V10h2c.55 0 1-.45 1-1s-.45-1-1-1H6c-1.1 0-2 .9-2 2v11c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V10c0-1.1-.9-2-2-2" />
		<path d="M12 16c.55 0 1-.45 1-1V5h1.79c.45 0 .67-.54.35-.85l-2.79-2.79c-.2-.2-.51-.2-.71 0L8.85 4.15a.5.5 0 0 0 .36.85H11v10c0 .55.45 1 1 1" />
	</svg>
);
export default ChromeShareIcon;
