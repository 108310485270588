import FusePageSimple from '@/@fuse/core/FusePageSimple';
import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect, useRef, useState } from 'react';
import history from '@/@history';
import { getPickups } from '@/app/services/students/students';
import { useMediaQuery } from '@material-ui/core';
import { useParams } from 'react-router';
import StudentTable from './StudentTable';
import { useDispatch } from 'react-redux';
import * as Actions from '@/app/store/actions/';
import CheckoutDrawer from './CheckoutDrawer';

const useStyles = makeStyles({
	layoutRoot: {},
});

function Room() {
	const dispatch = useDispatch();
	const classes = useStyles();
	const pageLayout = useRef(null);
	const [row] = useState(history?.location?.state);
	const [activeId, setActiveId] = useState(null);
	const [stuData, setStuData] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [refresh, setRefresh] = useState(true);
	const [drawerOpen, setDrawerOpen] = useState(false);
	const { id } = useParams();
	const showFixedDrawer = useMediaQuery('(min-width:1280px)');

	useEffect(() => {
		if (!id) {
			history.goBack();
		}
	}, [id]);

	useEffect(() => {
		let isMounted = true;
		if (activeId) {
			setIsLoading(true);
			getPickups(activeId)
				.then((res) => {
					if (!isMounted) return;
					res.data.parents = res.data.parents.filter((family) => family.can_pickup);
					res.data.approved_pickups = res.data.approved_pickups.filter((family) => family.can_pickup);
					setStuData(res.data);
				})
				.catch((err) => {
					if (!isMounted) return;
					if (err.response.status === 404) {
						history.push('/checkinout');
					}
					dispatch(
						Actions.showMessage({
							message: 'The contact does not exist',
							variant: 'error',
						})
					);
					setIsLoading(false);
				})
				.finally(() => {
					if (!isMounted) return;
					setIsLoading(false);
				});
		}
		return () => {
			isMounted = false;
		};
	}, [activeId]);

	useEffect(() => {
		navigator.serviceWorker.addEventListener('message', handleReceiveNotification);
		return () => {
			navigator.serviceWorker.removeEventListener('message', handleReceiveNotification);
		};
	}, []);

	const handleReceiveNotification = (e) => {
		if (e.data.data.click_action === 'check_in_out_data_notification') {
			setRefresh((prevState) => !prevState);
		}
	};
	return (
		<div>
			<div className="transition-all">
				<div
					className={drawerOpen && showFixedDrawer && 'mr-288 '}
					style={{ transition: 'margin-right .225s cubic-bezier(0, 0, 0.2, 1) 0ms' }}
				>
					<StudentTable
						row={row}
						setActiveId={setActiveId}
						activeId={activeId}
						setRefresh={setRefresh}
						refresh={refresh}
						setDrawerOpen={setDrawerOpen}
					/>
				</div>

				<CheckoutDrawer
					open={drawerOpen}
					setOpen={setDrawerOpen}
					stuData={stuData}
					roomId={id}
					setRefresh={setRefresh}
					refresh={refresh}
					setActiveId={setActiveId}
					isLoading={isLoading}
					activeId={activeId}
					fixed={showFixedDrawer}
				/>
			</div>
		</div>
	);
}

export default Room;
