import React, { createContext, useContext, useState } from 'react';

// Context for h264 history video player. Might be useful to simplify things when refactoring h264 player in the future
const HistoryVideoContext = createContext();


export const HistoryVideoProvider = ({ children }) => {
	const [clipLoading, setClipLoading] = useState(false);
	const [volume, setVolume] = useState(50);

	return (
		<HistoryVideoContext.Provider
			value={{
				volume,
				setVolume,
				clipLoading,
				setClipLoading,
			}}
		>
			{children}
		</HistoryVideoContext.Provider>
	);
};

export const useHistoryVideo = () => useContext(HistoryVideoContext);
