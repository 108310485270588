import { Button, IconButton } from '@material-ui/core';
import { ArrowBackIosRounded, KeyboardBackspaceOutlined } from '@material-ui/icons';
import React from 'react';

const BackButton = ({ onClick }) => {
	return (
		<IconButton
			size="medium"
			onClick={onClick}
			className="text-pd-black p-0 h-48 w-48 items-center justify-center"
			sx={{ }}
		>
			<KeyboardBackspaceOutlined color="inherit" className="h-36 w-36 p-4"/>
		</IconButton>
	);
};

export default BackButton;
