import DashboardCard from '@/app/fuse-layouts/layout1/components/Dashboard/DashboardCard';
import {
	Avatar,
	CircularProgress,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
} from '@material-ui/core';
import { useEffect, useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getRoomRatioSummary } from '@/app/services/rooms/rooms';
import RatioDataPopover from './RatioDataPopover';
import Pusher from 'pusher-js';
import { calculateRatio } from '../../rooms/RatioStats/helpers';
import history from '@/@history';

function RoomRatios() {
	const [viewAll, setViewAll] = useState(false);
	const [ratios, setRatios] = useState([]);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);
	const user = useSelector(({ auth }) => auth.user);

	const sortedRatios = useMemo(() => {
		return [...ratios].sort((a, b) => {
			const aStudentsPerStaffRatio = a.student_ratio_count / a.required_staff;
			const aRequiredStaff = Math.ceil(a.ratios.student_count / aStudentsPerStaffRatio);
			const aIsOutOfRatio = a.ratios.staff_count < aRequiredStaff;

			const bStudentsPerStaffRatio = b.student_ratio_count / b.required_staff;
			const bRequiredStaff = Math.ceil(b.ratios.student_count / bStudentsPerStaffRatio);
			const bIsOutOfRatio = b.ratios.staff_count < bRequiredStaff;

			if (aIsOutOfRatio && !bIsOutOfRatio) return -1;
			if (!aIsOutOfRatio && bIsOutOfRatio) return 1;
			return 0;
		});
	}, [ratios]);

	useEffect(() => {
		let isMounted = true;

		const pusher = new Pusher(import.meta.env.VITE_PUSHER_CHANNEL_ID, {
			cluster: import.meta.env.VITE_PUSHER_CLUSTER_ID,
			forceTLS: true,
		});
		const channel = pusher.subscribe(
			`ld-channel-${
				user.role[0] === 'school_admin' || user.role[0] === 'sub_admin' ? user.data.school.id : user.school?.id
			}`
		);

		channel.bind('ld-room-ratio-update-event', () => {
			if (isMounted) {
				setLoading(true);
				getRoomRatioSummary()
					.then((res) => {
						if (isMounted) {
							setRatios(res.data);
							setError(null);
						}
					})
					.catch((error) => {
						if (isMounted) {
							console.error('Error fetching updated room ratios:', error);
							setError('Failed to update room ratios');
						}
					})
					.finally(() => {
						if (isMounted) {
							setLoading(false);
						}
					});
			}
		});

		return () => {
			isMounted = false;
			pusher.disconnect();
		};
	}, [user]);

	useEffect(() => {
		let isMounted = true;
		setLoading(true);

		const fetchRatios = async () => {
			try {
				const res = await getRoomRatioSummary();
				if (isMounted) {
					setRatios(res.data);
					setError(null);
				}
			} catch (error) {
				if (isMounted) {
					setError('Failed to load room ratios');
				}
			} finally {
				if (isMounted) {
					setLoading(false);
				}
			}
		};

		fetchRatios();

		return () => {
			isMounted = false;
		};
	}, []);

	const getMoreRooms = () => {
		setViewAll(true);
	};

	const navigateToRoom = (room) => {
		history.push(`/rooms-room/${room.id}`);
	};

	return (
		<DashboardCard
			showViewMore={!viewAll && sortedRatios.length > 5}
			onViewMore={getMoreRooms}
			title="Current Room Ratios"
		>
			<div
				className={`rounded-8 relative ${viewAll ? 'overflow-y-scroll' : 'overflow-hidden'}`}
				style={{ height: '430px' }}
			>
				{loading && (
					<div className="flex justify-center absolute inset-0 items-center mt-16">
						<CircularProgress size={35} />
					</div>
				)}
				<TableContainer id="Rooms-Listing" component={Paper} elevation={0} className="">
					<Table stickyHeader className="w-full">
						<TableHead>
							<TableRow>
								<TableCell className="bg-white roomRatioHeader w-1/3 pr-0">Rooms</TableCell>

								<TableCell className="bg-white roomRatioHeader w-1/3 pl-0" align="left" colSpan={2}>
									Students/Staff Ratio
								</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{!ratios[0] && !loading && (
								<TableRow>
									<TableCell align="center" colSpan={3}>
										{error ? error : 'No rooms'}
									</TableCell>
								</TableRow>
							)}
							{sortedRatios.slice(0, viewAll ? sortedRatios.length : 5).map((room, key) => {
								const ratio = calculateRatio(room);
								const requiredStaff = Math.ceil(
									(room.ratios.student_count / ratio.students) * ratio.staff
								);
								const isOutOfRatio = room.ratios.staff_count < requiredStaff;

								return (
									<TableRow key={key} className="w-full">
										<TableCell padding="none" style={{ padding: '16px 0px' }}>
											<div className="flex items-center gap-10 cursor-pointer" onClick={() => navigateToRoom(room)}>
												<Avatar src={room?.photo} style={{ width: 42, height: 42 }} />
												<div className="flex ">
													<span className="m-4 font-bold text-sm hover:underline">{room?.name}</span>
												</div>
											</div>
										</TableCell>
										<TableCell align="right" padding="none" colSpan={1}>
											<RatioDataPopover room={room} outOfRatio={isOutOfRatio} />
										</TableCell>
										<TableCell align="right" className="pl-0">
											<span
												className={`font-bold ml-16 ${
													isOutOfRatio ? 'text-red-500' : 'text-green-500'
												}`}
											>
												{isOutOfRatio ? 'Out of ratio' : 'In Ratio'}
											</span>
										</TableCell>
									</TableRow>
								);
							})}
						</TableBody>
					</Table>
				</TableContainer>
			</div>
		</DashboardCard>
	);
}

export default RoomRatios;
