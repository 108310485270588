const config = {
	autoStartLoad: true,
	startLevel: -1,
	levelLoadingMaxRetry: 4,
	fragLoadingMaxRetry: 6,
	enableWorker: true,
	enableSoftwareAES: true,
	manifestLoadingMaxRetry: 3,
	manifestLoadingRetryDelay: 1000,
	backBufferLength: 60,
	maxBufferLength: 10,
	maxBufferHole: 0.5,
	maxFragLookUpTolerance: 0.25,
	liveSyncDurationCount: 3,
	liveMaxLatencyDurationCount: Infinity,
	enableCEA708Captions: true,
	enableWebVTT: true,
	progressive: false,
	lowLatencyMode: true,
	maxLoadingDelay: 6,
	minAutoBitrate: 0,
	fragLoadingRetryDelay: 1000,
	appendErrorMaxRetry: 3,
	// debug: false, // Disable debugging (set to true for debugging purposes)
	maxMaxBufferLength: 20,
	bufferLength: 30,
	forceKeyFrameOnDiscontinuity: true,

	capLevelToPlayerSize: true, // Limit quality based on player size
	maxBufferSize: 30 * 1000 * 1000, // 30mb
	// will be checking belwo first with cpu consumption
	// abrEwmaFastLive: 3.0,
	// abrEwmaSlowLive: 9.0,
	// abrEwmaFastVoD: 2.0,
	// abrEwmaSlowVoD: 4.0,
	// abrEwmaDefaultEstimate: 5e5,
	// abrBandWidthFactor: 0.8,
	// abrBandWidthUpFactor: 0.7,
	// abrMaxWithRealBitrate: false,
	// maxStarvationDelay: 2,
	// maxLoadingDelay: 4, // 6,
};

export default config;
