import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Check from '@material-ui/icons/Check';
import StepConnector from '@material-ui/core/StepConnector';

const useQontoStepIconStyles = makeStyles({
	root: {
		color: '#eaeaf0',
		display: 'flex',
		// height: 22,
		alignItems: 'center',
	},
	active: {
		color: '#784af4',
	},
	circle: {
		// width: 8,
		// height: 8,
		// borderRadius: '50%',
		backgroundColor: 'currentColor',
	},
	completed: {
		color: '#784af4',
		zIndex: 1,
		fontSize: 18,
	},
});

function QontoStepIcon(props) {
	const classes = useQontoStepIconStyles();
	const { active, completed } = props;

	return (
		<div
			className={clsx(classes.root, {
				[classes.active]: active,
			})}
		>
			{completed ? <Check className={classes.completed} /> : <div className={classes.circle} />}
		</div>
	);
}

QontoStepIcon.propTypes = {
	/**
	 * Whether this step is active.
	 */
	active: PropTypes.bool,
	/**
	 * Mark the step as completed. Is passed to child components.
	 */
	completed: PropTypes.bool,
};

const ColorlibConnector = withStyles({
	alternativeLabel: {
		top: 22,
	},
	line: {
		height: 1,
		border: '1px dotted #6079F4',
		margin: '0 15px',
		// padding: '0 30px',
	},
})(StepConnector);

const useColorlibStepIconStyles = makeStyles({
	root: {
		zIndex: 100,
		color: '#fff',
		width: 50,
		height: 50,
		display: 'flex',
		borderRadius: '50%',
		justifyContent: 'center',
		alignItems: 'center',
	},
});

function ColorlibStepIcon(props) {
	const classes = useColorlibStepIconStyles();
	const { active, completed } = props;

	const icons = {
		1: (
			<img
				src={`/assets/images/student-form-icons/personal-information${active ? '1' : ''}.png`}
				className="mx-auto"
			/>
		),
		2: <img src={`/assets/images/student-form-icons/medications${active ? '1' : ''}.png`} className="mx-auto" />,
		3: (
			<img
				src={`/assets/images/student-form-icons/room-attachments${active ? '1' : ''}.png`}
				className="mx-auto"
			/>
		),
		4: <img src={`/assets/images/student-form-icons/contacts${active ? '1' : ''}.png`} className="mx-auto" />,
		5: <img src={`/assets/images/student-form-icons/immunizations${active ? '1' : ''}.png`} className="mx-auto" />,
	};

	return <div className={classes.root}>{icons[String(props.icon)]}</div>;
}

ColorlibStepIcon.propTypes = {
	/**
	 * Whether this step is active.
	 */
	active: PropTypes.bool,
	/**
	 * Mark the step as completed. Is passed to child components.
	 */
	completed: PropTypes.bool,
	/**
	 * The label displayed in the step icon.
	 */
	icon: PropTypes.node,
};

const useStyles = makeStyles((theme) => ({
	root: {
		width: '70%',
		backgroundColor: 'transparent',
		margin: '0 auto',
		overflow: 'auto',
	},
	button: {
		marginRight: theme.spacing(1),
	},
	instructions: {
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(1),
	},
	step: {
		height: '100%',
	},
}));

function getSteps() {
	return [
		{ label: 'Personal Information', value: 'personal' },
		{ label: 'Medications', value: 'medication' },
		{ label: 'Room / Attachments', value: 'room-attachments' },
		{ label: 'Contacts', value: 'contacts' },
		{ label: 'Immunizations', value: 'immunizations' },
	];
}

const getCurrentState = (state) => {
	switch (state) {
		case 'personal':
			return 0;
		case 'medication':
			return 1;
		case 'room-attachments':
			return 2;
		case 'contacts':
			return 3;
		case 'immunizations':
			return 4;
		default:
			return 0;
	}
};

function TopProgress({ currentState, setCurrentState }) {
	const classes = useStyles();
	const [activeStep, setActiveStep] = React.useState();
	const [currentWidth, setCurrentWidth] = React.useState(window.innerWidth);
	const [maxAllowed, setMaxAllowed] = React.useState(0);
	const steps = getSteps();

	useEffect(() => {
		console.log('currentState', currentState);
		setActiveStep(getCurrentState(currentState));
		setMaxAllowed(Math.max(maxAllowed, getCurrentState(currentState)));
	}, [currentState]);

	useEffect(() => {
		const handleResize = () => {
			setCurrentWidth(window.innerWidth);
		};
		setCurrentWidth(window.innerWidth);
		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
	}, []);

	const handleIconClick = (value) => {
		if (getCurrentState(value) > maxAllowed) return;
		setCurrentState(value);
	};

	return (
		<div
			className="mt-0 md:mt-32"
			style={{
				scale: 0.5,
				transform: `scale(${currentWidth < 500 ? 0.5 : 1})`,
				width: currentWidth < 500 ? '100vw' : '',
				marginLeft: currentWidth < 500 ? (currentState === 'contacts' && '-20%') || '-20%' : '',
			}}
		>
			<Stepper
				className={classes.root}
				style={{ width: currentWidth < 500 ? '180%' : '70%' }}
				alternativeLabel
				activeStep={activeStep}
				connector={<ColorlibConnector />}
			>
				{steps.map((step) => (
					<Step className={classes.step} key={step.value}>
						<StepLabel
							onClick={() => handleIconClick(step.value)}
							style={{ fontWeight: 'bold', color: 'black' }}
							StepIconComponent={ColorlibStepIcon}
						>
							{step.label}
						</StepLabel>
					</Step>
				))}
			</Stepper>
		</div>
	);
}

export default TopProgress;
