import React from 'react';
import CustomButton from '@/app/customComponents/CustomButton/CustomButton';

function CancelDialog({ setSavedCoordinates, savedCoordinatesRef, setCancelDialog, setDisplayDialog, room }) {
	const handleClick = () => {
		setDisplayDialog(false);
		setCancelDialog(false);
		setSavedCoordinates(room?.camera?.coordinates || []);
		savedCoordinatesRef.current = room?.camera?.coordinates || [];
		document.exitFullscreen();
	};
	return (
		<div className="bg-white rounded-lg">
			<div className="popup">
				<div>
					<div className="icon-span">
						<h2>!</h2>
					</div>
					<h1 className="disable-text">Are you sure?</h1>
					<div className="flex gap-12">
						<CustomButton className="no-disable-btn" onClick={() => setCancelDialog(false)}>
							No
						</CustomButton>
						<CustomButton className="no-disable-btn" onClick={handleClick}>
							Yes
						</CustomButton>
					</div>
				</div>
			</div>
		</div>
	);
}

export default CancelDialog;
