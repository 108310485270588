import * as React from 'react';
const FirefoxIcon = (props) => (
	<svg xmlns="http://www.w3.org/2000/svg" width={48} height={48} viewBox="0 0 77.42 79.97" {...props}>
		<title>{'Firefox Browser logo'}</title>
		<defs>
			<radialGradient
				id="b"
				cx={-7907}
				cy={-8515}
				r={80.8}
				gradientTransform="translate(7974 8524)"
				gradientUnits="userSpaceOnUse"
			>
				<stop offset={0.129} stopColor="#ffbd4f" />
				<stop offset={0.186} stopColor="#ffac31" />
				<stop offset={0.247} stopColor="#ff9d17" />
				<stop offset={0.283} stopColor="#ff980e" />
				<stop offset={0.403} stopColor="#ff563b" />
				<stop offset={0.467} stopColor="#ff3750" />
				<stop offset={0.71} stopColor="#f5156c" />
				<stop offset={0.782} stopColor="#eb0878" />
				<stop offset={0.86} stopColor="#e50080" />
			</radialGradient>
			<radialGradient
				id="c"
				cx={-7937}
				cy={-8482}
				r={80.8}
				gradientTransform="translate(7974 8524)"
				gradientUnits="userSpaceOnUse"
			>
				<stop offset={0.3} stopColor="#960e18" />
				<stop offset={0.351} stopColor="#b11927" stopOpacity={0.74} />
				<stop offset={0.435} stopColor="#db293d" stopOpacity={0.343} />
				<stop offset={0.497} stopColor="#f5334b" stopOpacity={0.094} />
				<stop offset={0.53} stopColor="#ff3750" stopOpacity={0} />
			</radialGradient>
			<radialGradient
				id="d"
				cx={-7927}
				cy={-8533}
				r={58.53}
				gradientTransform="translate(7974 8524)"
				gradientUnits="userSpaceOnUse"
			>
				<stop offset={0.132} stopColor="#fff44f" />
				<stop offset={0.252} stopColor="#ffdc3e" />
				<stop offset={0.506} stopColor="#ff9d12" />
				<stop offset={0.526} stopColor="#ff980e" />
			</radialGradient>
			<radialGradient
				id="e"
				cx={-7946}
				cy={-8461}
				r={38.47}
				gradientTransform="translate(7974 8524)"
				gradientUnits="userSpaceOnUse"
			>
				<stop offset={0.353} stopColor="#3a8ee6" />
				<stop offset={0.472} stopColor="#5c79f0" />
				<stop offset={0.669} stopColor="#9059ff" />
				<stop offset={1} stopColor="#c139e6" />
			</radialGradient>
			<radialGradient
				id="f"
				cx={-7936}
				cy={-8492}
				r={20.4}
				gradientTransform="matrix(.972 -.235 .275 1.138 10090 7834)"
				gradientUnits="userSpaceOnUse"
			>
				<stop offset={0.206} stopColor="#9059ff" stopOpacity={0} />
				<stop offset={0.278} stopColor="#8c4ff3" stopOpacity={0.064} />
				<stop offset={0.747} stopColor="#7716a8" stopOpacity={0.45} />
				<stop offset={0.975} stopColor="#6e008b" stopOpacity={0.6} />
			</radialGradient>
			<radialGradient
				id="g"
				cx={-7938}
				cy={-8518}
				r={27.68}
				gradientTransform="translate(7974 8524)"
				gradientUnits="userSpaceOnUse"
			>
				<stop offset={0} stopColor="#ffe226" />
				<stop offset={0.121} stopColor="#ffdb27" />
				<stop offset={0.295} stopColor="#ffc82a" />
				<stop offset={0.502} stopColor="#ffa930" />
				<stop offset={0.732} stopColor="#ff7e37" />
				<stop offset={0.792} stopColor="#ff7139" />
			</radialGradient>
			<radialGradient
				id="h"
				cx={-7916}
				cy={-8536}
				r={118.1}
				gradientTransform="translate(7974 8524)"
				gradientUnits="userSpaceOnUse"
			>
				<stop offset={0.113} stopColor="#fff44f" />
				<stop offset={0.456} stopColor="#ff980e" />
				<stop offset={0.622} stopColor="#ff5634" />
				<stop offset={0.716} stopColor="#ff3647" />
				<stop offset={0.904} stopColor="#e31587" />
			</radialGradient>
			<radialGradient
				id="i"
				cx={-7927}
				cy={-8523}
				r={86.5}
				gradientTransform="matrix(.105 .995 -.653 .069 -4685 8470)"
				gradientUnits="userSpaceOnUse"
			>
				<stop offset={0} stopColor="#fff44f" />
				<stop offset={0.06} stopColor="#ffe847" />
				<stop offset={0.168} stopColor="#ffc830" />
				<stop offset={0.304} stopColor="#ff980e" />
				<stop offset={0.356} stopColor="#ff8b16" />
				<stop offset={0.455} stopColor="#ff672a" />
				<stop offset={0.57} stopColor="#ff3647" />
				<stop offset={0.737} stopColor="#e31587" />
			</radialGradient>
			<radialGradient
				id="j"
				cx={-7938}
				cy={-8508}
				r={73.72}
				gradientTransform="translate(7974 8524)"
				gradientUnits="userSpaceOnUse"
			>
				<stop offset={0.137} stopColor="#fff44f" />
				<stop offset={0.48} stopColor="#ff980e" />
				<stop offset={0.592} stopColor="#ff5634" />
				<stop offset={0.655} stopColor="#ff3647" />
				<stop offset={0.904} stopColor="#e31587" />
			</radialGradient>
			<radialGradient
				id="k"
				cx={-7919}
				cy={-8504}
				r={80.69}
				gradientTransform="translate(7974 8524)"
				gradientUnits="userSpaceOnUse"
			>
				<stop offset={0.094} stopColor="#fff44f" />
				<stop offset={0.231} stopColor="#ffe141" />
				<stop offset={0.509} stopColor="#ffaf1e" />
				<stop offset={0.626} stopColor="#ff980e" />
			</radialGradient>
			<linearGradient
				id="a"
				x1={70.79}
				x2={6.447}
				y1={12.39}
				y2={74.47}
				gradientTransform="translate(-1.3 -.004)"
				gradientUnits="userSpaceOnUse"
			>
				<stop offset={0.048} stopColor="#fff44f" />
				<stop offset={0.111} stopColor="#ffe847" />
				<stop offset={0.225} stopColor="#ffc830" />
				<stop offset={0.368} stopColor="#ff980e" />
				<stop offset={0.401} stopColor="#ff8b16" />
				<stop offset={0.462} stopColor="#ff672a" />
				<stop offset={0.534} stopColor="#ff3647" />
				<stop offset={0.705} stopColor="#e31587" />
			</linearGradient>
			<linearGradient
				id="l"
				x1={70.01}
				x2={15.27}
				y1={12.06}
				y2={66.81}
				gradientTransform="translate(-1.3 -.004)"
				gradientUnits="userSpaceOnUse"
			>
				<stop offset={0.167} stopColor="#fff44f" stopOpacity={0.8} />
				<stop offset={0.266} stopColor="#fff44f" stopOpacity={0.634} />
				<stop offset={0.489} stopColor="#fff44f" stopOpacity={0.217} />
				<stop offset={0.6} stopColor="#fff44f" stopOpacity={0} />
			</linearGradient>
		</defs>
		<path
			fill="url(#a)"
			d="M74.62 26.83c-1.684-4.052-5.1-8.427-7.775-9.81a40.27 40.27 0 0 1 3.925 11.76l.007.065c-4.382-10.92-11.81-15.33-17.88-24.92a47.099 47.099 0 0 1-.913-1.484 12.24 12.24 0 0 1-.427-.8 7.053 7.053 0 0 1-.578-1.535.1.1 0 0 0-.088-.1.138.138 0 0 0-.073 0c-.005 0-.013.009-.019.011s-.019.011-.028.015l.015-.026c-9.735 5.7-13.04 16.25-13.34 21.53a19.39 19.39 0 0 0-10.67 4.111 11.59 11.59 0 0 0-1-.758 17.97 17.97 0 0 1-.109-9.473 28.7 28.7 0 0 0-9.329 7.21h-.018c-1.536-1.947-1.428-8.367-1.34-9.708a6.928 6.928 0 0 0-1.294.687 28.22 28.22 0 0 0-3.788 3.245 33.84 33.84 0 0 0-3.623 4.347v.006-.007a32.73 32.73 0 0 0-5.2 11.74l-.052.256a61.89 61.89 0 0 0-.381 2.42c0 .029-.006.056-.009.085a36.94 36.94 0 0 0-.629 5.343v.2a38.76 38.76 0 0 0 76.95 6.554c.065-.5.118-.995.176-1.5a39.86 39.86 0 0 0-2.514-19.47zM29.95 57.17c.181.087.351.181.537.264l.027.017q-.282-.135-.564-.281zm40.828-28.314v-.037l.007.041z"
			transform="matrix(.98198 0 0 .98198 .697 .72)"
		/>
		<path
			fill="url(#b)"
			d="M74.62 26.83c-1.684-4.052-5.1-8.427-7.775-9.81a40.27 40.27 0 0 1 3.925 11.76v.037l.007.041a35.1 35.1 0 0 1-1.206 26.16c-4.442 9.531-15.19 19.3-32.02 18.82-18.18-.515-34.2-14.01-37.19-31.68-.545-2.787 0-4.2.274-6.465a28.88 28.88 0 0 0-.623 5.348v.2a38.76 38.76 0 0 0 76.95 6.554c.065-.5.118-.995.176-1.5a39.86 39.86 0 0 0-2.514-19.47z"
			transform="matrix(.98198 0 0 .98198 .697 .72)"
		/>
		<path
			fill="url(#c)"
			d="M74.62 26.83c-1.684-4.052-5.1-8.427-7.775-9.81a40.27 40.27 0 0 1 3.925 11.76v.037l.007.041a35.1 35.1 0 0 1-1.206 26.16c-4.442 9.531-15.19 19.3-32.02 18.82-18.18-.515-34.2-14.01-37.19-31.68-.545-2.787 0-4.2.274-6.465a28.88 28.88 0 0 0-.623 5.348v.2a38.76 38.76 0 0 0 76.95 6.554c.065-.5.118-.995.176-1.5a39.86 39.86 0 0 0-2.514-19.47z"
			transform="matrix(.98198 0 0 .98198 .697 .72)"
		/>
		<path
			fill="url(#d)"
			d="M55.78 31.38c.084.059.162.118.241.177a21.1 21.1 0 0 0-3.6-4.695C40.371 14.812 49.264.742 50.763.022L50.778 0c-9.735 5.7-13.04 16.25-13.34 21.53.452-.031.9-.069 1.362-.069a19.56 19.56 0 0 1 16.98 9.917z"
			transform="matrix(.98198 0 0 .98198 .697 .72)"
		/>
		<path
			fill="url(#e)"
			d="M38.82 33.79c-.064.964-3.47 4.289-4.661 4.289-11.02 0-12.81 6.667-12.81 6.667.488 5.614 4.4 10.24 9.129 12.68.216.112.435.213.654.312q.569.252 1.138.466a17.24 17.24 0 0 0 5.043.973c19.32.906 23.06-23.1 9.119-30.07a13.38 13.38 0 0 1 9.345 2.269 19.56 19.56 0 0 0-16.98-9.917c-.46 0-.91.038-1.362.069a19.39 19.39 0 0 0-10.67 4.111c.591.5 1.258 1.168 2.663 2.553 2.63 2.591 9.375 5.275 9.39 5.59z"
			transform="matrix(.98198 0 0 .98198 .697 .72)"
		/>
		<path
			fill="url(#f)"
			d="M38.82 33.79c-.064.964-3.47 4.289-4.661 4.289-11.02 0-12.81 6.667-12.81 6.667.488 5.614 4.4 10.24 9.129 12.68.216.112.435.213.654.312q.569.252 1.138.466a17.24 17.24 0 0 0 5.043.973c19.32.906 23.06-23.1 9.119-30.07a13.38 13.38 0 0 1 9.345 2.269 19.56 19.56 0 0 0-16.98-9.917c-.46 0-.91.038-1.362.069a19.39 19.39 0 0 0-10.67 4.111c.591.5 1.258 1.168 2.663 2.553 2.63 2.591 9.375 5.275 9.39 5.59z"
			transform="matrix(.98198 0 0 .98198 .697 .72)"
		/>
		<path
			fill="url(#g)"
			d="M24.96 24.36c.314.2.573.374.8.531a17.97 17.97 0 0 1-.109-9.473 28.7 28.7 0 0 0-9.329 7.21c.189-.005 5.811-.106 8.638 1.732z"
			transform="matrix(.98198 0 0 .98198 .697 .72)"
		/>
		<path
			fill="url(#h)"
			d="M.354 42.16c2.991 17.67 19.01 31.17 37.19 31.68 16.83.476 27.58-9.294 32.02-18.82a35.1 35.1 0 0 0 1.206-26.16v-.037c0-.029-.006-.046 0-.037l.007.065c1.375 8.977-3.191 17.67-10.33 23.56l-.022.05c-13.91 11.33-27.22 6.834-29.91 5q-.282-.135-.564-.281c-8.109-3.876-11.46-11.26-10.74-17.6a9.953 9.953 0 0 1-9.181-5.775 14.62 14.62 0 0 1 14.25-.572 19.3 19.3 0 0 0 14.55.572c-.015-.315-6.76-3-9.39-5.59-1.405-1.385-2.072-2.052-2.663-2.553a11.59 11.59 0 0 0-1-.758c-.23-.157-.489-.327-.8-.531-2.827-1.838-8.449-1.737-8.635-1.732h-.018c-1.536-1.947-1.428-8.367-1.34-9.708a6.928 6.928 0 0 0-1.294.687 28.22 28.22 0 0 0-3.788 3.245 33.84 33.84 0 0 0-3.638 4.337v.006-.007a32.73 32.73 0 0 0-5.2 11.74c-.019.079-1.396 6.099-.717 9.221z"
			transform="matrix(.98198 0 0 .98198 .697 .72)"
		/>
		<path
			fill="url(#i)"
			d="M52.42 26.86a21.1 21.1 0 0 1 3.6 4.7c.213.161.412.321.581.476 8.787 8.1 4.183 19.55 3.84 20.36 7.138-5.881 11.7-14.58 10.33-23.56-4.384-10.93-11.82-15.34-17.88-24.93a47.099 47.099 0 0 1-.913-1.484 12.24 12.24 0 0 1-.427-.8 7.053 7.053 0 0 1-.578-1.535.1.1 0 0 0-.088-.1.138.138 0 0 0-.073 0c-.005 0-.013.009-.019.011s-.019.011-.028.015c-1.499.711-10.39 14.79 1.66 26.83z"
			transform="matrix(.98198 0 0 .98198 .697 .72)"
		/>
		<path
			fill="url(#j)"
			d="M56.6 32.04a8.083 8.083 0 0 0-.581-.476c-.079-.059-.157-.118-.241-.177a13.38 13.38 0 0 0-9.345-2.269c13.94 6.97 10.2 30.97-9.119 30.07a17.24 17.24 0 0 1-5.043-.973q-.569-.213-1.138-.466c-.219-.1-.438-.2-.654-.312l.027.017c2.694 1.839 16 6.332 29.91-5l.022-.05c.347-.81 4.951-12.26-3.84-20.36z"
			transform="matrix(.98198 0 0 .98198 .697 .72)"
		/>
		<path
			fill="url(#k)"
			d="M21.35 44.74s1.789-6.667 12.81-6.667c1.191 0 4.6-3.325 4.661-4.289a19.3 19.3 0 0 1-14.55-.572 14.62 14.62 0 0 0-14.25.572 9.953 9.953 0 0 0 9.181 5.775c-.718 6.337 2.632 13.72 10.74 17.6.181.087.351.181.537.264-4.733-2.445-8.641-7.069-9.129-12.68z"
			transform="matrix(.98198 0 0 .98198 .697 .72)"
		/>
		<path
			fill="url(#l)"
			d="M74.62 26.83c-1.684-4.052-5.1-8.427-7.775-9.81a40.27 40.27 0 0 1 3.925 11.76l.007.065c-4.382-10.92-11.81-15.33-17.88-24.92a47.099 47.099 0 0 1-.913-1.484 12.24 12.24 0 0 1-.427-.8 7.053 7.053 0 0 1-.578-1.535.1.1 0 0 0-.088-.1.138.138 0 0 0-.073 0c-.005 0-.013.009-.019.011s-.019.011-.028.015l.015-.026c-9.735 5.7-13.04 16.25-13.34 21.53.452-.031.9-.069 1.362-.069a19.56 19.56 0 0 1 16.98 9.917 13.38 13.38 0 0 0-9.345-2.269c13.94 6.97 10.2 30.97-9.119 30.07a17.24 17.24 0 0 1-5.043-.973q-.569-.213-1.138-.466c-.219-.1-.438-.2-.654-.312l.027.017q-.282-.135-.564-.281c.181.087.351.181.537.264-4.733-2.446-8.641-7.07-9.129-12.68 0 0 1.789-6.667 12.81-6.667 1.191 0 4.6-3.325 4.661-4.289-.015-.315-6.76-3-9.39-5.59-1.405-1.385-2.072-2.052-2.663-2.553a11.59 11.59 0 0 0-1-.758 17.97 17.97 0 0 1-.109-9.473 28.7 28.7 0 0 0-9.329 7.21h-.018c-1.536-1.947-1.428-8.367-1.34-9.708a6.928 6.928 0 0 0-1.294.687A28.22 28.22 0 0 0 9.9 16.858a33.84 33.84 0 0 0-3.623 4.347v.006-.007a32.73 32.73 0 0 0-5.2 11.74l-.052.256c-.073.341-.4 2.073-.447 2.445a45.09 45.09 0 0 0-.572 5.403v.2a38.76 38.76 0 0 0 76.95 6.554c.065-.5.118-.995.176-1.5a39.86 39.86 0 0 0-2.514-19.47zm-3.845 1.991.007.041z"
			transform="matrix(.98198 0 0 .98198 .697 .72)"
		/>
	</svg>
);
export default FirefoxIcon;
