import { AppBar, Avatar, Divider, Popover, Tab, Tabs, Toolbar, withStyles } from '@material-ui/core';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { BsChevronDown } from 'react-icons/bs';

const StyledTabs = withStyles({
	indicator: {
		backgroundColor: 'white',
	},
})(Tabs);

const StyledTab = withStyles({
	root: ({ selected }) => ({
		color: 'black',
		background: selected ? 'rgba(0, 157, 255, .3)' : 'transparent',
		borderRadius: '10px',
		textTransform: 'capitalize',
		minWidth: '125px',
		paddingInline: '10px',
		paddingBlock: '2px',
		minHeight: '3em',
	}),
})(Tab);

const CheckedInList = ({ people, type }) => {
	return (
		<div>
			<div className="my-8 mx-8 mt-0 flex flex-col gap-4">
				<Divider className="mb-4" />
				{people.length ? (
					people?.map((person, index) => {
						const isLast = index === people.length - 1;
						return (
							<Fragment key={person.id}>
								<div className="flex gap-16 items-center">
									<Avatar src={person.thumb} className="h-32 w-32" />
									<span>
										{person.last_name}, {person.first_name}
									</span>
								</div>
								{!isLast && <Divider className="my-4" />}
							</Fragment>
						);
					})
				) : (
					<div className="flex items-center justify-center h-full">
						<span className="italic">No {type} </span>
					</div>
				)}
			</div>
		</div>
	);
};

const RatioDataPopover = ({ room, isOutOfRatio }) => {
	const [anchorEl, setAnchorEl] = useState(null);
	const [selectedTab, setSelectedTab] = useState('student');
	const [hovered, setHovered] = useState(false);

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};
	const handleChange = (event, newValue) => {
		setSelectedTab(newValue);
	};
	// sort the students by last name
	const sortedStudents = room?.ratios?.students?.sort((a, b) => {
		return a.last_name.localeCompare(b.last_name);
	});
	const sortedStaff = room?.ratios?.staff?.sort((a, b) => {
		return a.last_name.localeCompare(b.last_name);
	});
	return (
		<>
			<button
				onClick={handleClick}
				className={`flex items-center gap-4 font-bold text-black  border border-transparent rounded-full py-4 px-8 whitespace-no-wrap ${
					hovered || Boolean(anchorEl) ? 'border-grey-300 shadow-sm' : ''
				}`}
				onMouseEnter={() => setHovered(true)}
				onMouseLeave={() => setHovered(false)}
			>
				{room?.ratios.student_count} : {room?.ratios.staff_count}
				<BsChevronDown className="text-black w-10" />
			</button>
			<Popover
				open={Boolean(anchorEl)}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
				transformOrigin={{ vertical: 'top', horizontal: 'left' }}
				PaperProps={{ className: 'rounded-lg, h-320' }}
			>
				<div className="px-4 pt-0">
					<div className="sticky top-0 pt-4 bg-white z-99">
						<StyledTabs value={selectedTab} onChange={handleChange}>
							<StyledTab
								value="student"
								label={`${room?.ratios.student_count} student${
									room?.ratios.student_count > 1 ? 's' : ''
								}`}
								selected={selectedTab === 'student'}
							/>
							<StyledTab
								value="staff"
								label={`${room?.ratios.staff_count} staff`}
								selected={selectedTab === 'staff'}
							/>
						</StyledTabs>
					</div>

					{selectedTab === 'student' && <CheckedInList people={sortedStudents} type="Students" />}
					{selectedTab === 'staff' && <CheckedInList people={sortedStaff} type="Staff" />}
				</div>
			</Popover>
		</>
	);
};

export default RatioDataPopover;
