import { Box, CircularProgress, Fade, Modal, responsiveFontSizes, Typography } from '@material-ui/core';
import React from 'react';
import CustomButton from '../CustomButton/CustomButton';
import { ErrorOutlineSharp } from '@material-ui/icons';

const ConfirmModal = ({
	open,
	setOpen,
	title,
	onCancel = () => setOpen(false),
	onConfirm,
	confirmText = 'Yes',
	cancelText = 'No',
	isRequesting = false,
}) => {
	const style = {
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		maxWidth: 400,
		width: { xs: '90vw', sm: 400 },
		bgcolor: 'background.paper',
		borderRadius: '8px',
		boxShadow: 24,
		pt: 4,
		px: 4,
		pb: 3,
	};
	return (
		<Modal open={open} onClose={() => setOpen(false)}>
			<Fade in={open}>
				<Box sx={style}>
					<Box
						sx={{
							display: 'flex',
							flexDirection: 'column',
							justifyContent: 'center',
							alignItems: 'center',
							gap: 20,
						}}
					>
						<Box
							sx={{
								display: 'flex',
								flexDirection: 'column',
								alignItems: 'center',
								gap: 10,
								fontSize: '7rem',
							}}
						>
							<ErrorOutlineSharp color="error" fontSize="inherit" />
							<div className="bg-pd-blue w-20 h-2"></div>
						</Box>
						<Typography variant="h6" align="center" component="h2" sx={{ fontWeight: 600 }}>
							{title}
						</Typography>
						<Box sx={{ display: 'flex', gap: 10 }}>
							{isRequesting ? (
								<CircularProgress />
							) : (
								<>
									<CustomButton onClick={onCancel}>{cancelText}</CustomButton>
									<CustomButton onClick={onConfirm}>{confirmText}</CustomButton>
								</>
							)}
						</Box>
					</Box>
				</Box>
			</Fade>
		</Modal>
	);
};

export default ConfirmModal;
