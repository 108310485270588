import React from 'react';
import './CustomButton.css';

function CustomButton({
	onClick,
	size,
	type,
	height,
	width,
	fontSize,
	children,
	disabled,
	padding,
	isWarning,
	textAlign,
	marginTop,
	background,
	style,
	marginLeft,
	marginRight,
	id,
	maxWidth,
	minWidth,
	...props
}) {
	return (
		<button
			className={`add-btn add-btn-${size} hover:shadow-blue-md cursor-pointer ${disabled ? 'disabled' : ''} ${
				isWarning ? 'add-btn-warning' : ''
			} `}
			style={{
				...style,
				height,
				width,
				fontSize,
				padding,
				textAlign,
				marginTop,
				background,
				marginLeft,
				marginRight,
				maxWidth,
				minWidth,
			}}
			type={type || 'button'}
			disabled={disabled}
			onClick={onClick}
			id={id}
			{...props}
		>
			{children}
		</button>
	);
}

export default CustomButton;
