import React from 'react';
import authRoles from '../../../auth/authRoles';
import Rooms from './Rooms';
import Room from './Room';

const CheckInOutConfig = {
	settings: {
		layout: {
			config: {},
		},
	},
	routes: [
		{
			path: '/checkinout/:id',
			// component: Room
			component: React.lazy(() => import('./Room')),
		},
		{
			path: '/checkinout',
			// component: Rooms
			component: React.lazy(() => import('./Rooms')),
		},
	],
	auth: authRoles.subadmin,
};

export default CheckInOutConfig;
