/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/media-has-caption */
import React, { useState, useEffect, useRef, useMemo } from 'react';
import Hls from 'hls.js';
import './index.css';
import { Avatar, IconButton } from '@material-ui/core';
import { getRooms, setBlurBox } from '@/app/services/liveStreaming/liveStreaming';
import { uploadFile } from '@/app/services/imageUpload/imageUpload';
import { useDispatch } from 'react-redux';
import * as Actions from '@/app/store/actions';
import CustomButton from '@/app/customComponents/CustomButton/CustomButton';
import config from './hlsconfig';
import { Info } from '@material-ui/icons';
import BlurInfoDialog from './BlurInfoDialog';
import CancelDialog from './CancelDialog.jsx';

function Streaming({ room, setRefresh }) {
	const dispatch = useDispatch();
	const [videoBannerText, setVideoBannerText] = useState('Loading');
	const [isFullScreen, setIsFullScreen] = useState(false);
	const [overlayImgUrl, setOverlatImgUrl] = useState('');
	const [isLoading, setIsLoading] = useState(false);
	const [displayDialog, setDisplayDialog] = useState(false);
	const [cancelDialog, setCancelDialog] = useState(false);
	const playerRef = useRef(null);
	const boxRef = useRef(null);
	const [undoStack, setUndoStack] = useState([]);
	const [redoStack, setRedoStack] = useState([]);
	const maxUndoStack = 5;
	let currentPlaybackPosition = 0;
	let hls = null;
	const timeouts = [];
	let canvas;
	let overlayImage;
	let ctx;

	let rectX, rectY, rectWidth, rectHeight;
	let isDrawing = false;
	let startX, startY;
	const [savedCoordinates, setSavedCoordinates] = useState(room?.camera?.coordinates || []);
	const savedCoordinatesRef = useRef(room?.camera?.coordinates || []);
	const delta = 6;
	let video;
	let modifiedImage = new Image();
	const apiReCall = async () => {
		try {
			const res = await getRooms(room.name, '', 1);
			return res.data.data[0];
		} catch (err) {
			console.log(err);
		}
	};

	useEffect(() => {
		let roomLoading = '';

		const clearTimeouts = () => {
			timeouts.forEach((timeoutId) => {
				clearTimeout(timeoutId);
			});
		};
		const initializeHls = () => {
			if (hls && room?.stream_url?.session_url) {
				hls.loadSource(room?.stream_url?.session_url);

				hls.attachMedia(playerRef.current);

				hls.on(Hls.Events.MEDIA_ATTACHED, () => {
					hls.on(Hls.Events.ERROR, handleHlsError);
					hls.on(Hls.Events.MANIFEST_PARSED, () => {
						hls.startLoad();
					});
				});

				hls.on(Hls.Events.MANIFEST_LOADED, () => {
					// HLS manifest has been loaded
					playerRef.current.play().catch((error) => {});
				});
			}
		};
		const handleFragmentLoadError = async () => {
			// Skip the problematic fragment and continue streaming
			const currentLevel = hls.currentLevel;
			const currentFragment = hls.mediaBuffer ? hls.mediaBuffer : hls.media;

			if (currentLevel !== null && currentFragment !== null) {
				hls.nextLoadPosition = hls.config.startPosition;

				if (roomLoading !== room.name) {
					roomLoading = room.name;

					const roomData = await apiReCall();
					// Retry loading the next fragment after a delay
					setTimeout(() => {
						clearTimeouts();

						if (hls && roomData?.stream_url?.session_url) {
							hls.loadSource(roomData?.stream_url?.session_url);
							hls.attachMedia(playerRef.current);
							hls.on(Hls.Events.MEDIA_ATTACHED, () => {
								// Restore the current playback position

								playerRef.current.currentTime = currentPlaybackPosition;
								hls.startLoad();
							});
							roomLoading = '';
							retryNetworkCount = 0;
						}
					}, config.fragLoadingRetryDelay);
				}
			} else {
				// If currentLevel or currentFragment is null, there is no more content to load
			}
		};
		let retryNetworkCount = 0;
		const handleHlsError = (event, data) => {
			if (data.fatal) {
				switch (data.type) {
					case Hls.ErrorTypes.NETWORK_ERROR:
						retryNetworkCount++;
						handleFragmentLoadError();
						break;
					case Hls.ErrorTypes.MEDIA_ERROR:
						handleFragmentLoadError();
						break;
					default:
						handleFragmentLoadError();
						break;
				}
			}
		};

		const handleBuffering = () => {
			setVideoBannerText('Connecting');

			timeouts.push(
				setTimeout(() => {
					clearTimeouts();
					if (hls && room?.stream_url?.session_url) {
						hls.attachMedia(playerRef.current);
						hls.loadSource(room?.stream_url?.session_url);

						hls.on(Hls.Events.MEDIA_ATTACHED, () => {
							hls.on(Hls.Events.MANIFEST_PARSED, () => {
								hls.startLoad();
							});
						});
					}
				}, 10000)
			);
		};

		const handleFullScreenChange = () => {
			const result = checkFullscreen();
			if (result) {
				setIsFullScreen(true);
				video = document.getElementById(room?.id);
				canvas = document.getElementById(`canvas-${room?.id}`);
				overlayImage = document.getElementById(`overlayImage-${room?.id}`);
				ctx = canvas.getContext('2d');
				canvas.width = window.innerWidth;
				canvas.height = window.innerHeight;
				canvas.addEventListener('mousedown', startDrawing);
				canvas.addEventListener('mousemove', drawRectangle);
				canvas.addEventListener('mouseup', stopDrawing);
				modifiedImage.src = overlayImage.src;
				setUndoStack([]);
				setRedoStack([]);
				redrawCanvas();
			} else {
				setIsFullScreen(false);
				setDisplayDialog(false);
				setCancelDialog(false);
				setSavedCoordinates(room?.camera?.coordinates || []);
				savedCoordinatesRef.current = room?.camera?.coordinates || [];
				canvas.removeEventListener('mousedown', startDrawing);
				canvas.removeEventListener('mousemove', drawRectangle);
				canvas.removeEventListener('mouseup', stopDrawing);
			}
		};

		if (room?.stream_url?.session_url) {
			if (Hls.isSupported()) {
				hls = new Hls(config);

				initializeHls();

				playerRef.current.addEventListener('waiting', handleBuffering);
				playerRef.current.addEventListener('playing', () => {
					clearTimeouts();
					setVideoBannerText('Live');
				});

				playerRef.current.addEventListener('pause', () => {});
			}
		}
		if (boxRef.current) {
			boxRef.current.addEventListener('fullscreenchange', handleFullScreenChange);
		}

		return () => {
			clearTimeouts();

			if (hls) {
				hls.destroy();
				hls = null;
			}
			if (boxRef.current) {
				boxRef.current.removeEventListener('fullscreenchange', handleFullScreenChange);
			}
			if (playerRef.current) {
				playerRef.current.removeEventListener('waiting', handleBuffering);
				playerRef.current.removeEventListener('playing', () => {
					clearTimeouts();
					setVideoBannerText('Live');
				});
				playerRef.current.removeEventListener('pause', () => {});
			}
		};
	}, [room?.stream_url?.session_url]);

	useEffect(() => {
		if (isFullScreen) {
			redrawCanvas();
		}
	}, [savedCoordinates]);

	const goFullScreen = () => {
		if (boxRef.current.requestFullscreen) {
			boxRef.current.requestFullscreen();
		} else if (boxRef.current.webkitRequestFullscreen) {
			boxRef.current.webkitRequestFullscreen();
		} else if (boxRef.current.msRequestFullscreen) {
			boxRef.current.msRequestFullscreen();
		}
	};

	const checkFullscreen = () => {
		return !!(
			document.fullscreenElement === boxRef.current ||
			document.webkitFullscreenElement === boxRef.current ||
			document.msFullscreenElement === boxRef.current ||
			document.mozFullScreenElement === boxRef.current
		);
	};

	function startDrawing(e) {
		console.log('Start drawing');
		isDrawing = true;
		startX = e.pageX;
		startY = e.pageY;
	}

	function drawRectangle(e) {
		if (!isDrawing) return;

		let currentX = e.pageX;
		let currentY = e.pageY;

		rectX = Math.min(startX, currentX);
		rectY = Math.min(startY, currentY);
		rectWidth = Math.abs(currentX - startX);
		rectHeight = Math.abs(currentY - startY);
		if (rectWidth < delta && rectHeight < delta) {
			// Click!
			return;
		}

		ctx.clearRect(0, 0, canvas.width, canvas.height);
		ctx.drawImage(modifiedImage, 0, 0);
		redrawCanvas();
		ctx.strokeStyle = 'red';
		ctx.lineWidth = 2;
		ctx.strokeRect(rectX, rectY, rectWidth, rectHeight);
	}

	function stopDrawing(event) {
		const diffX = Math.abs(event.pageX - startX);
		const diffY = Math.abs(event.pageY - startY);
		isDrawing = false;
		if (diffX > delta || diffY > delta) {
			const newCoordinates = {
				x: rectX,
				y: rectY,
				width: rectWidth,
				height: rectHeight,
			};
			savedCoordinatesRef.current = [...savedCoordinatesRef.current, newCoordinates];
			setSavedCoordinates((prev) => [...prev, newCoordinates]);
			setUndoStack((prev) =>
				[...prev, { action: 'add', coordinates: newCoordinates, index: savedCoordinatesRef.current.length - 1 }]
					.reverse()
					.slice(0, maxUndoStack)
					.reverse()
			);
		} else {
			let isActiveObject = false;
			// check if any of the rectangle is clicked, if found, set it as active
			const clickedObject = savedCoordinatesRef.current
				.slice()
				.reverse()
				.map((coordinate) => {
					if (
						event.offsetX >= coordinate.x &&
						event.offsetX <= coordinate.x + coordinate.width &&
						event.offsetY >= coordinate.y &&
						event.offsetY <= coordinate.y + coordinate.height &&
						!isActiveObject
					) {
						isActiveObject = true;
						return { ...coordinate, active: true };
					} else {
						return { ...coordinate, active: false };
					}
				});
			setSavedCoordinates(clickedObject.reverse());
			savedCoordinatesRef.current = clickedObject.reverse();
		}
	}

	function saveImage() {
		setIsLoading(true);
		savedCoordinatesRef.current = savedCoordinatesRef.current.map((coordinate) => ({
			...coordinate,
			active: false,
		}));
		redrawCanvas();
		const modifiedImage = new Image();
		overlayImage = document.getElementById(`overlayImage-${room?.id}`);
		modifiedImage.src = overlayImage.src;

		modifiedImage.onload = function () {
			const canvas = document.getElementById(`canvas-${room?.id}`);
			const dataUrl = canvas.toDataURL();
			const base64Data = dataUrl.replace(/^data:image\/\w+;base64,/, '');
			const buffer = Buffer.from(base64Data, 'base64');
			uploadFile(
				buffer,
				`web/public/room-blurs/${room.name.split(' ').join('')}-${room.id}-blur-overlay-${Date.now()}.png`
			)
				.then((res) => {
					const url = `${import.meta.env.VITE_S3_BASE_URL}${res}`;
					const coordinates = savedCoordinatesRef.current.map((coordinate) => ({
						x: coordinate.x,
						y: coordinate.y,
						width: coordinate.width,
						height: coordinate.height,
					}));
					setBlurBox({ url, coordinates }, room.id)
						.then((res) => {
							console.log(res, 'res');
							setOverlatImgUrl(url);
							setSavedCoordinates(coordinates);
							savedCoordinatesRef.current = coordinates;
							setRefresh((prev) => !prev);
							// exit full screen
							// press esc
							document.exitFullscreen();
						})
						.catch((err) => {
							document.exitFullscreen();
							dispatch(
								Actions.showMessage({
									message: err?.response?.data?.message || 'Failed to save',
									variant: 'error',
								})
							);
							console.log(err, 'err');
						})
						.finally(() => {
							setIsLoading(false);
						});
				})
				.catch((err) => {
					setIsLoading(false);
					dispatch(
						Actions.showMessage({
							message: 'Failed to upload image',
							variant: 'error',
						})
					);
				});
		};
	}

	function redrawCanvas() {
		canvas = document.getElementById(`canvas-${room?.id}`);
		ctx = canvas.getContext('2d');
		ctx.clearRect(0, 0, canvas.width, canvas.height);
		ctx.drawImage(modifiedImage, 0, 0);

		ctx.save();
		const delbtn = document.getElementById('delete-btn');
		let isActiveObject = false;
		savedCoordinatesRef.current.forEach((savedRect) => {
			ctx.filter = 'blur(10px)';
			ctx.fillStyle = 'rgba(211,211,211, 1)';
			ctx.fillRect(savedRect.x, savedRect.y, savedRect.width, savedRect.height);
			if (savedRect.active) {
				ctx.filter = 'none';
				ctx.strokeStyle = 'red';
				ctx.lineWidth = 3;
				ctx.strokeRect(savedRect.x, savedRect.y, savedRect.width, savedRect.height);
				// show delete btn on the clicked object
				delbtn.style.display = 'block';
				delbtn.style.top = `${savedRect.y}px`;
				delbtn.style.left = `${savedRect.x + savedRect.width - 32}px`;
				delbtn.style.zIndex = 600;
				isActiveObject = true;
			}
		});
		if (!isActiveObject) {
			delbtn.style.display = 'none';
		}
		ctx.restore();
		ctx.strokeStyle = 'red';
		ctx.lineWidth = 2;
	}
	const deleteCoordinate = () => {
		const index = savedCoordinates.findIndex((savedRect) => savedRect.active);
		if (index !== -1) {
			setSavedCoordinates((savedCoordinates) => {
				const newArr = [...savedCoordinates];
				newArr.splice(index, 1);
				return newArr;
			});
			savedCoordinatesRef.current = savedCoordinatesRef.current.filter((savedRect) => !savedRect.active);
			setUndoStack((prev) =>
				[...prev, { action: 'delete', coordinates: savedCoordinates[index], index: index }]
					.reverse()
					.slice(0, maxUndoStack)
					.reverse()
			);
		}
	};
	const isLive = () => videoBannerText === 'Live';

	const undo = () => {
		const lastAction = undoStack[undoStack.length - 1];
		if (lastAction) {
			if (lastAction.action === 'add') {
				const newArr = [
					...savedCoordinatesRef.current.slice(0, lastAction.index),
					...savedCoordinatesRef.current.slice(lastAction.index + 1),
				];
				setSavedCoordinates(newArr);
				savedCoordinatesRef.current = newArr;
			} else if (lastAction.action === 'delete') {
				const newArr = [...savedCoordinatesRef.current];
				newArr.splice(lastAction.index, 0, lastAction.coordinates);
				setSavedCoordinates(newArr);
				savedCoordinatesRef.current = newArr;
			}
			setRedoStack((redoStack) => [...redoStack, lastAction].reverse().slice(0, maxUndoStack).reverse());
			setUndoStack((undoStack) => [...undoStack.slice(0, undoStack.length - 1)]);
		}
	};

	const redo = () => {
		const lastAction = redoStack[redoStack.length - 1];
		if (lastAction) {
			if (lastAction.action === 'add') {
				const newArr = [...savedCoordinatesRef.current];
				newArr.splice(lastAction.index, 0, lastAction.coordinates);
				setSavedCoordinates(newArr);
				savedCoordinatesRef.current = newArr;
			} else if (lastAction.action === 'delete') {
				const newArr = [
					...savedCoordinatesRef.current.slice(0, lastAction.index),
					...savedCoordinatesRef.current.slice(lastAction.index + 1),
				];
				setSavedCoordinates(newArr);
				savedCoordinatesRef.current = newArr;
			}
			setUndoStack((undoStack) => [...undoStack, lastAction].reverse().slice(0, maxUndoStack).reverse());
			setRedoStack((redoStack) => [...redoStack.slice(0, redoStack.length - 1)]);
		}
	};

	const showDialog = () => {
		setDisplayDialog(true);
		console.log('True Set');
	};

	const handleCancelClick = () => {
		setDisplayDialog(false);
		setCancelDialog(true);
	};

	return (
		<div className="flex justify-center items-center aspect-ratio-16-9">
			<div ref={boxRef} className="w-full video-player-box rounded-lg cursor-pointer" onClick={goFullScreen}>
				<video
					preload="true"
					id={room?.id}
					ref={playerRef}
					autoPlay
					muted
					className={`rounded-lg video-player pointer-events-none object-fill`}
					controlsList="nodownload noplaybackrate"
				></video>
				<div className="video-player-title flex items-center col-gap-8">
					<Avatar src={room?.thumb} />
					<div>{room?.name}</div>
				</div>
				{room?.stream_url?.session_url ? (
					<>
						<div className={`btn_live ${isLive() ? '' : 'not_btn_live'} z-20`}>
							{videoBannerText || 'Live'}
							<span className={`${isLive() ? 'live-icon' : 'not-live-icon'}`} />
						</div>
					</>
				) : (
					''
				)}
				{!room?.stream_url?.session_url ? (
					<div className="stream-not-running-text">Live Streaming Is Not Available</div>
				) : (
					''
				)}
				{isFullScreen && (
					<>
						{displayDialog && (
							<div className="blur-fullscreen">
								<BlurInfoDialog fullScreen={true} setFalse={setDisplayDialog} />
							</div>
						)}
						{cancelDialog && (
							<div className="cancel-dialog">
								<CancelDialog
									setSavedCoordinates={setSavedCoordinates}
									savedCoordinatesRef={savedCoordinatesRef}
									setCancelDialog={setCancelDialog}
									setDisplayDialog={setDisplayDialog}
								/>
							</div>
						)}
						<div className="absolute" style={{ zIndex: 500, top: '19px', right: '0' }}>
							<IconButton onClick={showDialog} className="p-6 px-12">
								<Info />
							</IconButton>
						</div>
						<img
							id={`overlayImage-${room?.id}`}
							className="img-overlay-blur"
							src="assets/images/live/transparent-bg.png"
							alt="Overlay Image"
						/>
						<canvas className="canvas-blur" id={`canvas-${room?.id}`}></canvas>
						<button className="hidden absolute" id="delete-btn" onClick={deleteCoordinate}>
							<img src="assets/images/dlt.png" alt="" />
						</button>
						{!isLoading && (
							<>
								{undoStack.length > 0 && (
									<button
										id="undo-btn"
										style={{
											position: 'absolute',
											bottom: 0,
											left: 0,
											zIndex: 300,
										}}
										onClick={undo}
									>
										<img
											src="assets/images/live/undo-icon.png"
											alt="undo-icon"
											className="bg-transparent"
										/>
									</button>
								)}
								{redoStack.length > 0 && (
									<button
										style={{
											position: 'absolute',
											bottom: 0,
											left: 50,
											zIndex: 300,
										}}
										onClick={redo}
									>
										<img src="assets/images/live/redo-icon.png" alt="redo-icon" />
									</button>
								)}
								{undoStack.length && (
									<>
										<CustomButton
											style={{
												position: 'absolute',
												bottom: 10,
												right: 110,
												zIndex: 300,
											}}
											id="blur-save-btn"
											onClick={saveImage}
										>
											Save
										</CustomButton>
										<CustomButton
											style={{
												position: 'absolute',
												bottom: 10,
												right: 0,
												zIndex: 300,
											}}
											id="blur-cancel-btn"
											onClick={handleCancelClick}
										>
											Cancel
										</CustomButton>
									</>
								)}
							</>
						)}
					</>
				)}
				{(overlayImgUrl || room?.stream_url?.overlay_image) && !isFullScreen && (
					<img
						id="overlayImage"
						className="img-overlay-blur"
						src={overlayImgUrl || room?.stream_url?.overlay_image}
						style={{
							position: 'absolute',
							top: 0,
							left: 0,
						}}
						height={boxRef.current?.clientHeight}
						width={boxRef.current?.clientWidth}
					/>
				)}
			</div>
		</div>
	);
}

export default Streaming;
