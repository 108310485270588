import axios from 'axios';

const createContact = (data) => {
	return axios.post('api/v2/admin/students/contact', data);
};

const updateContact = (id, student_id, data) => {
	return axios.put(`api/v2/admin/students/contact/${id}?student_id=${student_id}`, data);
};
const updateContactv2 = (id, data) => {	
	return axios.put(`api/v2/contact/${id}`, data);
};

export { createContact, updateContact, updateContactv2 };
