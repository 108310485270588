import React from 'react';
import './index.css';
import history from '@/@history';

function BigPlus() {
	return (
		<div className="pb-20 md:pb-0">
			<div
				className="flex justify-center items-center  aspect-ratio-16-9"
				onClick={() => {
					history.push('/livestreaming-CameraRegistration');
				}}
			>
				<div className="w-full video-player-box rounded-lg bg-color">
					<img src="assets/images/plus-big.png" className="w-1/6"></img>
				</div>
			</div>
		</div>
	);
}

export default BigPlus;
