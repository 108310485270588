import React, { forwardRef, useImperativeHandle } from 'react';
import { TextField } from '@material-ui/core';
import { useMaskito } from '@maskito/react';

const CustomPhoneInput = forwardRef(({ value, onChange, divProps, ...props }, ref) => {
	const inputRef = useMaskito({
		options: {
			mask: ['(', /\d/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/],
		},
	});

	// Use useImperativeHandle to forward the ref from react-hook-form to the input element
	useImperativeHandle(ref, () => ({
		focus: () => {
			inputRef.current.focus();
		},
	}));

	return (
		<div className="py-12">
			{/* This div is passed the divProps to maintain any styles from previous phone input component */}
			<div {...divProps}>
				<TextField
					// removing the +1 from any phone number that might have it from the previously used phone input component
					value={value.replace(/^\+1\s*/, '')}
					onInput={onChange}
					inputRef={inputRef}
					fullWidth
					{...props}
				/>
			</div>
		</div>
	);
});

export default CustomPhoneInput;
