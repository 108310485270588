// TODO: update for audio functionality when we have a stream with audio
import { Slider, Tooltip, withStyles } from '@material-ui/core';
import React, { useRef, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { useHistoryVideo } from '@/app/main/subadmin/livestreaming/historycamera/HistoryProvider';

const TimeSlider = withStyles({
	root: {
		color: 'rgb(77, 160, 238)',
		height: 0,
		paddingBottom: '5px',
		width: '100%',
	},
	thumb: {
		height: 10,
		width: 10,
		backgroundColor: '#fff',
		marginTop: -2.5,
		marginLeft: -5,
		'&:focus, &:hover, &$active': {
			boxShadow: 'inherit',
		},
		'&:after': {
			width: 10,
			height: 10,
		},
	},
	active: {},
	valueLabel: {
		left: 'calc(-50% + 4px)',
	},
	track: {
		height: 5,
		borderRadius: 4,
		backgroundColor: 'white',
	},
	rail: {
		height: 5,
		backgroundColor: 'white',
		borderRadius: 4,
	},
})(Slider);

const ValueLabelComponent = ({ children, open, value }) => (
	<Tooltip open={open} enterTouchDelay={0} placement="top" title={value}>
		{children}
	</Tooltip>
);

function H264Controls({
	room,
	playerRef,
	boxRef,
	showControls = true,
	isSeeking,
	selectedSeekTime,
	handleSliderChange,
	handleSliderChangeCommitted,
	handleSliderChangeStart,
	onVolumeChange,
	startTime,
	pendingSeekTime,
	toggleAudioPlayback,
}) {
	const { volume } = useHistoryVideo();
	const playButtonRef = useRef(null);
	const volumeButtonRef = useRef(null);
	const volumeRef = useRef(null);
	const muteRef = useRef(null);
	const volumeHighRef = useRef(null);
	const volumeLowRef = useRef(null);
	const fullScreenRef = useRef(null);
	const isPaused = playerRef?.current?.paused;
	// const [hoveredValue, setHoveredValue] = useState(null);
	// const progressBarRef = useRef(null);
	// const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });

	useEffect(() => {
		let interval;
		volumeRef.current?.addEventListener('input', updateVolume);
		volumeButtonRef.current?.addEventListener('click', toggleMute);
		fullScreenRef.current.addEventListener('click', toggleFullScreen);
		boxRef.current?.addEventListener('fullscreenchange', updateFullscreenButton);

		return () => {
			volumeRef.current?.removeEventListener('input', updateVolume);
			volumeButtonRef.current?.removeEventListener('click', toggleMute);
			fullScreenRef.current.removeEventListener('click', toggleFullScreen);
			boxRef.current?.removeEventListener('fullscreenchange', updateFullscreenButton);

			clearInterval(interval);
		};
	}, [room?.id]);


	useEffect(() => {
		updateVolumeIcon();
	}, [volume]);

	// updateVolume updates the video's volume
	// and disables the muted state if active
	function updateVolume() {
		if (playerRef.current.muted) {
			playerRef.current.muted = false;
		}
		onVolumeChange(volumeRef.current?.value);
	}

	// updateVolumeIcon updates the volume icon so that it correctly reflects
	// the volume of the video
	function updateVolumeIcon() {
		volumeHighRef.current?.classList.add('hidden');
		volumeLowRef.current?.classList.add('hidden');
		muteRef.current?.classList.add('hidden');

		volumeButtonRef.current?.setAttribute('data-title', 'Mute (m)');
		// volume is a string when coming from the input, so convert to number to compare
		if (playerRef.current.muted || +volume === 0) {
			muteRef.current?.classList.remove('hidden');
			volumeButtonRef.current?.setAttribute('data-title', 'Unmute (m)');
		} else if (volume > 0 && volume <= 0.5) {
			volumeLowRef.current?.classList.remove('hidden');
		} else {
			volumeHighRef.current?.classList.remove('hidden');
		}
	}

	function toggleMute() {
		playerRef.current.muted = !playerRef.current.muted;

		if (playerRef.current.muted) {
			volumeRef.current?.setAttribute('data-volume', volumeRef.current?.value);
			volumeRef.current.value = 0;
			onVolumeChange(0);
		} else {
			// if the volume is 0, set it to 0.5 when unmuting
			volumeRef.current.value = volumeRef.current?.dataset.volume > 0 ? volumeRef.current?.dataset.volume : 0.5;
			onVolumeChange(volumeRef.current?.dataset.volume > 0 ? volumeRef.current?.dataset.volume : 0.5);
		}
	}

	function togglePlay() {
		// because audio is running through pcmPlayer, we need to toggle it separately from the video
		toggleAudioPlayback();
		if (playerRef.current?.paused || playerRef.current?.ended) {
			playerRef.current.play();
		} else {
			playerRef.current?.pause();
		}
	}

	function toggleFullScreen() {
		if (document.fullscreenElement) {
			document.exitFullscreen();
		} else if (document.webkitFullscreenElement) {
			document.webkitExitFullscreen();
		} else if (boxRef.current.requestFullscreen) {
			boxRef.current.requestFullscreen();
		} else if (boxRef.current.webkitRequestFullscreen) {
			boxRef.current.webkitRequestFullscreen();
		} else if (boxRef.current.msRequestFullscreen) {
			boxRef.current.msRequestFullscreen();
		}
	}

	// updateFullscreenButton changes the icon of the full screen button
	// and tooltip to reflect the current full screen state of the video
	function updateFullscreenButton() {
		const fullscreenButton = document.getElementById(`fullscreen-button-${room?.id}`);
		if (document.fullscreenElement) {
			fullscreenButton?.setAttribute('data-title', 'Exit full screen (f)');
		} else {
			fullscreenButton?.setAttribute('data-title', 'Full screen (f)');
		}
	}

	return (
		<div className="video-controls z-99 rounded-b-lg" id={`video-controls-${room?.id}`}>
			{/* TODO: delete OR implement hover time label on progress bar if requested */}
			{/* {hoveredValue !== null && !isSeeking && (
				<div
					style={{
						position: 'fixed',
						left: tooltipPosition.x + 20,
						bottom: 0,
						backgroundColor: 'white',
						padding: '5px',
						borderRadius: '5px',
						boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
					}}
				>
					{hoveredTime}
				</div>
			)} */}
			<div
				className={`flex flex-col gap-4 px-16 py-16 transition-all w-full transform`}
				style={{
					backgroundImage: 'linear-gradient(to bottom, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.5))',
					transition: 'all .3s ease',
					transform: showControls ? 'translateY(0)' : 'translateY(100%)',
				}}
			>
				<div className="video-progress flex-grow">
					<div className="relative bottom-10">
						<TimeSlider
							value={isSeeking ? pendingSeekTime : playerRef?.current?.currentTime + selectedSeekTime}
							min={0}
							max={1200}
							step={0.1}
							size="large"
							onChange={handleSliderChange}
							onChangeCommitted={handleSliderChangeCommitted}
							onMouseDown={handleSliderChangeStart}
							ValueLabelComponent={ValueLabelComponent}
							valueLabelFormat={(value) =>
								dayjs(startTime, 'HH:mm:ss').add(value, 'second').format('H:mm:ss')
							}
						/>
					</div>
				</div>

				<div className="bottom-controls gap-4">
					<div className="left-controls">
						<button
							ref={playButtonRef}
							data-title={isPaused ? 'Play (k)' : 'Pause (k)'}
							id={`play-${room?.id}`}
							onClick={togglePlay}
						>
							<svg className="playback-icons svg-video-controls">
								<use href="#play-icon" className={isPaused ? '' : 'hidden'}></use>
								<use href="#pause" className={isPaused ? 'hidden' : ''}></use>
							</svg>
						</button>

						<div className="volume-controls">
							<button
								data-title="Mute (m)"
								className="volume-button"
								ref={volumeButtonRef}
								id={`volume-button-${room?.id}`}
							>
								<svg className="svg-video-controls">
									<use ref={muteRef} href="#volume-mute"></use>
									<use ref={volumeLowRef} className="hidden" href="#volume-low"></use>
									<use ref={volumeHighRef} className="hidden" href="#volume-high"></use>
								</svg>
							</button>

							<input
								className="volume rounded-lg hidden sm:block"
								id={`volume-${room?.id}`}
								ref={volumeRef}
								defaultValue={volume}
								data-mute="0.5"
								type="range"
								max="1"
								min="0"
								step="0.01"
							/>
						</div>
					</div>
					<div className="right-controls">
						<button
							data-title="Full screen (f)"
							className="fullscreen-button"
							ref={fullScreenRef}
							id={`fullscreen-button-${room?.id}`}
						>
							<svg className="svg-video-controls">
								<use href="#fullscreen"></use>
								<use href="#fullscreen-exit" className="hidden"></use>
							</svg>
						</button>
					</div>
				</div>
			</div>
			<svg style={{ display: 'none' }}>
				<defs>
					<symbol id="pause" viewBox="0 0 24 24">
						<path d="M14.016 5.016h3.984v13.969h-3.984v-13.969zM6 18.984v-13.969h3.984v13.969h-3.984z"></path>
					</symbol>

					<symbol id="play-icon" viewBox="0 0 24 24">
						<path d="M8.016 5.016l10.969 6.984-10.969 6.984v-13.969z"></path>
					</symbol>

					<symbol id="volume-high" viewBox="0 0 24 24">
						<path d="M14.016 3.234q3.047 0.656 5.016 3.117t1.969 5.648-1.969 5.648-5.016 3.117v-2.063q2.203-0.656 3.586-2.484t1.383-4.219-1.383-4.219-3.586-2.484v-2.063zM16.5 12q0 2.813-2.484 4.031v-8.063q1.031 0.516 1.758 1.688t0.727 2.344zM3 9h3.984l5.016-5.016v16.031l-5.016-5.016h-3.984v-6z"></path>
					</symbol>

					<symbol id="volume-low" viewBox="0 0 24 24">
						<path d="M5.016 9h3.984l5.016-5.016v16.031l-5.016-5.016h-3.984v-6zM18.516 12q0 2.766-2.531 4.031v-8.063q1.031 0.516 1.781 1.711t0.75 2.32z"></path>
					</symbol>

					<symbol id="volume-mute" viewBox="0 0 24 24">
						<path d="M12 3.984v4.219l-2.109-2.109zM4.266 3l16.734 16.734-1.266 1.266-2.063-2.063q-1.547 1.313-3.656 1.828v-2.063q1.172-0.328 2.25-1.172l-4.266-4.266v6.75l-5.016-5.016h-3.984v-6h4.734l-4.734-4.734zM18.984 12q0-2.391-1.383-4.219t-3.586-2.484v-2.063q3.047 0.656 5.016 3.117t1.969 5.648q0 2.203-1.031 4.172l-1.5-1.547q0.516-1.266 0.516-2.625zM16.5 12q0 0.422-0.047 0.609l-2.438-2.438v-2.203q1.031 0.516 1.758 1.688t0.727 2.344z"></path>
					</symbol>

					<symbol id="fullscreen" viewBox="0 0 24 24">
						<path d="M14.016 5.016h4.969v4.969h-1.969v-3h-3v-1.969zM17.016 17.016v-3h1.969v4.969h-4.969v-1.969h3zM5.016 9.984v-4.969h4.969v1.969h-3v3h-1.969zM6.984 14.016v3h3v1.969h-4.969v-4.969h1.969z"></path>
					</symbol>

					<symbol id="fullscreen-exit" viewBox="0 0 24 24">
						<path d="M15.984 8.016h3v1.969h-4.969v-4.969h1.969v3zM14.016 18.984v-4.969h4.969v1.969h-3v3h-1.969zM8.016 8.016v-3h1.969v4.969h-4.969v-1.969h3zM5.016 15.984v-1.969h4.969v4.969h-1.969v-3h-3z"></path>
					</symbol>
				</defs>
			</svg>
		</div>
	);
}

export default H264Controls;
