import SubAdminDashboardConfig from './home/SubAdminDashboardConfig';
import CheckInOutConfig from './checkinout/CheckInOutConfig';
import StudentsConfig from './students/StudentsConfig';
import StudentInformationConfig from './studentInformation/StudentInformationConfig';
import RoomsConfig from './rooms/RoomsConfig';
import StaffConfig from './staff/StaffConfig';
import StaffScheduleConfig from './staffSchedules/StaffScheduleConfig';
import CalendarConfig from './calendar/CalendarConfig';
import EventConfig from './TopEvents/EventsConfig';
import MessagingConfig from './messaging/MessagingConfig';
import SubAdminProfileConfig from './profile/profileConfig';
import ReportsConfig from './reports/ReportsConfig';
import SettingsConfig from './settings/SettingsConfig';
import LivestreamingConfig from './livestreaming/LivestreamingConfig';
import SchoolAdminConfig from './schooladmin/SchoolAdminConfig';

const subAdminConfig = [
	SubAdminProfileConfig,
	SubAdminDashboardConfig,
	CheckInOutConfig,
	CalendarConfig,
	EventConfig,
	StudentsConfig,
	StudentInformationConfig,
	RoomsConfig,
	StaffConfig,
	StaffScheduleConfig,
	MessagingConfig,
	SettingsConfig,
	ReportsConfig,
	LivestreamingConfig,
	SchoolAdminConfig,
];

export default subAdminConfig;
