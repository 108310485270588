import React, { useState, useEffect } from 'react';
import { PwaContext } from './PwaContext';
import PwaInstructionsModal from '@/app/customComponents/modals/PwaInstructionsModal';

const PwaContextProvider = ({ children }) => {
	const [pwaPrompt, setPwaPrompt] = useState(false);
	const [deferredPrompt, setDeferredPrompt] = useState(null);
	const [showPwaBanner, setShowPwaBanner] = useState(false);
	const [showPwaInstructions, setShowPwaInstructions] = useState(false);
	const [bannerDismissed, setBannerDismissed] = useState(sessionStorage.getItem('bannerDismissed') === 'true');

	// listen for the 'beforeinstallprompt' event and capture it so we can use it to display the native prompt
	useEffect(() => {
		const beforeInstallPromptHandler = (e) => {
			e.preventDefault();
			setDeferredPrompt(e);
			setPwaPrompt(true);
			if (!bannerDismissed) {
				setShowPwaBanner(true);
			}
		};
		window.addEventListener('beforeinstallprompt', beforeInstallPromptHandler);

		return () => {
			window.removeEventListener('beforeinstallprompt', beforeInstallPromptHandler);
		};
	}, [bannerDismissed]);


	const handlePwaPromptClick = (isBanner = false) => {
		setPwaPrompt(false);
		if (!deferredPrompt) return;
		deferredPrompt.prompt();
		deferredPrompt.userChoice.then((choiceResult) => {
			if (choiceResult.outcome === 'accepted') {
				setDeferredPrompt(null);
				sessionStorage.setItem('showPwaBanner', 'false');
			} else {
				if (isBanner) {
					setShowPwaBanner(false);
					setBannerDismissed(true);
					sessionStorage.setItem('bannerDismissed', 'true');
				}
			}
		});
	};

	const handleClosePwaBanner = () => {
		setShowPwaBanner(false);
		setBannerDismissed(true);
		sessionStorage.setItem('bannerDismissed', 'true');
	};

	const handleShowPwaInstructions = () => {
		setShowPwaInstructions(true);
	};

	const handleHidePwaInstructions = () => {
		setShowPwaInstructions(false);
	};

	return (
		<>
			<PwaInstructionsModal open={showPwaInstructions} setOpen={setShowPwaInstructions} maxWidth={800} />
			<PwaContext.Provider
				value={{
					pwaPrompt,
					setPwaPrompt,
					deferredPrompt,
					setDeferredPrompt,
					handlePwaPromptClick,
					showPwaBanner,
					handleClosePwaBanner,
					handleShowPwaInstructions,
					handleHidePwaInstructions,
				}}
			>
				{children}
			</PwaContext.Provider>
		</>
	);
};

export default PwaContextProvider;
