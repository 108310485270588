/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Fragment, useContext, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import './Topbar.css';
import * as Actions from '@/app/store/actions';
import { getMaydayMessages } from '@/app/services/notificationtopbar/notificationtopbar';
import { acknowledgeMaydayMessage } from '@/app/services/notifications/notifications';
import { KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons';
import { IconButton } from '@material-ui/core';
import { getDayTime } from '@/utils/utils';
import InsetInputButton from '@/app/customComponents/InsetInputButton/InsetInputButton';
import { MaydayMessageContext } from '@/app/context/mayday-messages/MaydayMessageContext';

function Topbar({ setUnreadCount, unreadCount }) {
	const {
		maydayBarVisibility,
		setMaydayBarVisibility,
		maydayMessageData,
		setMaydayMessageData,
		maydayMessageIsReading,
		setMaydayMessageIsReading,
		setUpdateNotifications,
		updateMaydayMessages,
		setUpdateMaydayMessages,
	} = useContext(MaydayMessageContext);
	const dispatch = useDispatch();
	const user = useSelector(({ auth }) => auth.user);
	let interval;

	const fetchMaydayMessages = () => {
		getMaydayMessages().then((res) => {
			const notifi = res.data.data.filter(
				(notification) =>
					!notification.deleted_at &&
					new Date().toISOString().split('T')[0] === notification.created_at.split(' ')[0]
			);
			setMaydayMessageData({
				activeIndex: 0,
				notifications: notifi.map((notif) => {
					return {
						...notif,
						created_at: new Date(`${notif.created_at}Z`).toISOString(),
					};
				}),
			});
			if (notifi.length) {
				interval = setInterval(() => {
					setMaydayMessageData((prevState) => {
						const n = prevState.notifications.filter(
							(notification) =>
								new Date().toISOString().split('T')[0] === notification.created_at.split('T')[0]
						);
						return {
							notifications: n,
							activeIndex: (prevState.activeIndex + 1) % (n.length > 25 ? 25 : n.length || 1),
						};
					});
				}, 5000);
			}
		});
	};

	useEffect(() => {
		// Initial fetch on mount
		const timeout = setTimeout(fetchMaydayMessages, 500);

		return () => {
			clearInterval(interval);
			clearTimeout(timeout);
		};
	}, [user]);

	useEffect(() => {
		if (updateMaydayMessages) {
			fetchMaydayMessages();
			setUpdateMaydayMessages(false);
		}
	}, [updateMaydayMessages]);

	const handleAcknowledgeMaydayMessage = (notification) => {
		setMaydayMessageIsReading(true);
		acknowledgeMaydayMessage(notification.auto_id)
			.then((res) => {
				if (res.status === 200) {
					setUnreadCount((prevCount) => prevCount - 1);

					setMaydayMessageData((prevData) => {
						const notifications = prevData.notifications.filter((n) => n.id !== notification.id);
						if (notifications.length === 0) {
							setMaydayBarVisibility(false);
						}
						return {
							...prevData,
							notifications,
							activeIndex: prevData.activeIndex % (notifications.length || 1),
						};
					});

					setUpdateNotifications(true);
				}
			})
			.catch((err) => {
				dispatch(
					Actions.showMessage({
						message: err?.response?.data?.message || 'Failed to mark notification as read',
						variant: 'error',
					})
				);
			})
			.finally(() => {
				setMaydayMessageIsReading(false);
			});
	};

	return (
		<div className="relative topbar-wrapper flex justify-center">
			{maydayMessageData.notifications?.length > 0 && (
				<div className="flex items-center w-full gap-4">
					<div className="border flex rounded-8 border-blue p-0 w-full items-center">
						<div className="items-center flex-grow">
							<div className="flex justify-between items-stretch relative min-h-46 mayday-bar">
								<div className="flex rounded p-6 pr-20" style={{ width: '80%' }}>
									<div className="flex items-center gap-2 justify-between">
										<div className="flex gap-10">
											<div className=" md:flex">
												<span className="block top-barr-text">
													{`${getDayTime(
														maydayMessageData.notifications[maydayMessageData.activeIndex]
															?.created_at
													)}`}
												</span>
												<span className="block top-barr-text">{`
									${maydayMessageData.notifications[maydayMessageData.activeIndex]?.data?.data?.body}: ${
													maydayMessageData.notifications[maydayMessageData.activeIndex]?.data
														?.data?.room.name
												}`}</span>
											</div>
										</div>
									</div>
								</div>
								<InsetInputButton
									disabled={maydayMessageIsReading}
									onClick={() =>
										handleAcknowledgeMaydayMessage(
											maydayMessageData.notifications[maydayMessageData.activeIndex]
										)
									}
								>
									OK
								</InsetInputButton>
							</div>
						</div>
					</div>
					<IconButton
						onClick={() => {
							setMaydayBarVisibility(!maydayBarVisibility);
						}}
						className="w-28 h-28"
						variant="contained"
					>
						{maydayBarVisibility ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
					</IconButton>
				</div>
			)}

			{maydayBarVisibility && maydayMessageData.notifications.length > 0 && (
				<div
					style={{
						maxHeight: maydayBarVisibility ? '300px' : 0,
						padding: maydayBarVisibility ? '2rem' : 0,
						overflow: 'auto',
						minWidth: 'max-content',
					}}
					className="topbar-dropdown bg-white rounded-lg "
				>
					{maydayMessageData.notifications.map((notification, ind, arr) => {
						return (
							<Fragment key={notification.id}>
								<div
									className={`top-barr-dd items-center flex-grow py-12 ${
										ind === arr.length - 1 ? 'pb-0' : 'border-b'
									} ${ind === 0 && 'pt-0'}`}
								>
									<div className="flex img-topbarr " style={{ flexBasis: '80%' }}>
										<img src={notification?.data?.data?.room.photo} alt="" />
										<div className="flex items-center">
											<div className="break-top-barr-text">
												{`${notification?.data?.data?.room.name} :
							${notification?.data?.data?.body}
							`}
											</div>
										</div>
									</div>

									<div className="cross-img flex ">
										<div className="break-top-barr-text mr-10 whitespace-no-wrap">
											{getDayTime(notification?.created_at)}
										</div>
										<img
											onClick={() => handleAcknowledgeMaydayMessage(notification)}
											className="cursor-pointer"
											src="assets/images/cross-icon.svg"
											alt=""
										/>
									</div>
								</div>
							</Fragment>
						);
					})}
				</div>
			)}
		</div>
	);
}

export default Topbar;
