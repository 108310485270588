/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Actions from '@/app/store/actions/';
import * as UserActions from '@/app/auth/store/actions/user.actions';
import history from '@/@history';
import axios from 'axios';
import JwtService from '@/app/services/jwtService';
import * as PusherPushNotifications from '@pusher/push-notifications-web';
import SchoolListDialog from './SchoolListDialog';
import secureLocalStorage from 'react-secure-storage';

/* The `ViewAs` function is a React component that renders a UI for switching between different user
roles in an application. This is rendered in the navigation bar of super admin and super school admin (super admin acting as school admin) */
function ViewAs() {
	const dispatch = useDispatch();
	let goBackInProgress = false;
	const user = useSelector(({ auth }) => auth.user);
	const handleSwitchAdmin = () => {
		dispatch(
			Actions.openDialog({
				children: <SchoolListDialog />,
			})
		);
	};

	const handleBackToSuperAdmin = () => {
		if (goBackInProgress) {
			return;
		}
		goBackInProgress = true;
		window.navigator.serviceWorker.ready.then((serviceWorkerRegistration) => {
			const beamsClient = new PusherPushNotifications.Client({
				instanceId: import.meta.env.VITE_BEAMS_INSTANCE_ID,
				serviceWorkerRegistration,
			});
			beamsClient.clearAllState().then(() => console.log('Cleared all beams state'));
		});
		JwtService.setSession(secureLocalStorage.getItem('superadmin_token'));
		secureLocalStorage.removeItem('superadmin_token');
		const tempUser = JSON.parse(secureLocalStorage.getItem('user'));
		tempUser.role[0] = 'super_admin';
		axios.get('/api/v2/profile').then((res) => {
			tempUser.data.displayName = `${res.data.first_name} ${res.data.last_name}`;
			secureLocalStorage.setItem('user', JSON.stringify(tempUser));
			dispatch(UserActions.setUserData(tempUser));
			history.push('/company');
		});
	};

	return (
		<div className="flex flex-col px-40 gap-20 mt-16">
			{user.role[0] === 'super_admin' || user.role[0] === 'super_school_admin' ? (
				<div className="text-white">
					<p className="text-white mb-10">View As:</p>
					<button
						type="button"
						onClick={handleSwitchAdmin}
						className="text-white w-full text-center border-white rounded-full py-6 px-2 border flex items-center justify-center hover:shadow-md"
					>
						Select School <i className="fas fa-angle-down ml-8" />
					</button>
				</div>
			) : (
				''
			)}
			{user.role[0] === 'super_school_admin' && (
				<button
					onClick={handleBackToSuperAdmin}
					className="text-white text-center border-white rounded-full py-6 hover:shadow-md border flex items-center justify-center"
				>
					Back to Company
				</button>
			)}
		</div>
	);
}

export default ViewAs;
