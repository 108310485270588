import React, { useEffect, useState } from 'react';
import CustomButton from '@/app/customComponents/CustomButton/CustomButton';
import { changeCheckInCode, getStudent } from '@/app/services/students/students';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { IconButton, CircularProgress, Avatar } from '@material-ui/core';
import history from '@/@history';
import * as Actions from '@/app/store/actions/';
import { useDispatch } from 'react-redux';
import { VisibilityOutlined, VisibilityOffOutlined, ArrowForwardIos, ArrowBackIos } from '@material-ui/icons';
import DeleteconfirmContact from './DeleteconfirmContact';

function ContactView({ student, isLoading, setCurrentState, isAdmin, token, setStudent, refresh, setRefresh }) {
	const dispatch = useDispatch();
	useEffect(() => {
		if (student) {
			setHiddenCodes(CodeHidden(student, true));
			setChangeCodeLoader(CodeHidden(student, false));
		}
	}, [student]);
	const [hiddenCodes, setHiddenCodes] = useState([]);
	const [changeCodeLoader, setChangeCodeLoader] = useState([]);

	const handleCheckInCodeShowHide = (index) => {
		const temp = [...hiddenCodes];
		temp[index] = !hiddenCodes[index];
		setHiddenCodes(temp);
	};
	const CodeHidden = (std, bool) => {
		return std.parents
			.concat(std.approved_pickups)
			?.concat(std.extended_family)
			?.map((parent) => bool);
	};
	const goToAddEditContact = (edit, contact, allParents) => {
		if (isAdmin) {
			history.push({
				pathname: '/students-contact',
				state: { isEdit: edit, row: student, contact, tab: 'contacts', allParents },
			});
		} else {
			history.push('/studentcontact', {
				isEdit: edit,
				row: student,
				contact,
				token,
				tab: 'contacts',
				allParents,
			});
		}
	};

	const changeCode = (parentId, index) => {
		if (index || index === 0) {
			const temp = [...changeCodeLoader];
			temp[index] = true;
			setChangeCodeLoader(temp);
		}
		changeCheckInCode(parentId)
			.then((resp) => {
				getStudent(params?.id)
					.then((res) => {
						setStudent(res.data);
					})
					.catch((err) => {
						console.log({ ...err });
					})
					.finally(() => {
						if (index || index === 0) {
							const temp = [...changeCodeLoader];
							temp[index] = false;
							setChangeCodeLoader(temp);
						}
					});
			})
			.catch((err) => {
				console.log({ ...err });
			});
	};
	const handleNext = () => {
		setCurrentState('immunizations');
	};
	const handledelete = (familie) => {
		dispatch(
			Actions.openDialog({
				children: (
					<DeleteconfirmContact
						student={student}
						row={familie}
						setStudent={setStudent}
						refresh={refresh}
						setRefresh={setRefresh}
					/>
				),
			})
		);
	};
	return (
		<>
			<div className="contact-view-responsive">
				<div className="flex items-center justify-between mx-auto mb-16">
					<span className="md:text-lg lg:text-xl self-end font-extrabold">Contacts</span>
					<div className="flex justify-between">
						<span className="mx-4">
							<CustomButton
								variant="secondary"
								height="46"
								width="104px"
								fontSize="15px"
								disabled={isLoading}
								onClick={() => goToAddEditContact(0, null, student?.parents)}
							>
								<FontAwesomeIcon icon={faPlus} />
								<span> Add</span>
							</CustomButton>
						</span>
					</div>
				</div>
				<div className="bg-white p-8 sm:p-16 md:p-32 rounded-lg overflow-auto">
					<table className="w-full">
						<thead className="border-b">
							<tr>
								<th className="bg-white school-table-header text-left p-16">Contact</th>
								<th
									style={{
										minWidth: '100px',
									}}
									className="bg-white school-table-header text-left p-16"
								>
									Email
								</th>
								<th
									style={{
										minWidth: '100px',
									}}
									className="bg-white school-table-header text-left p-16"
								>
									Phone
								</th>
								<th className="bg-white school-table-header text-left p-16">Emergency Contact</th>
								<th className="bg-white school-table-header text-left p-16" align="center">
									Can pickup
								</th>
								<th className="bg-white school-table-header text-left p-16 action-div">Checkin code</th>
								<th className="bg-white school-table-header text-left p-16 action-div">Action</th>
							</tr>
						</thead>
						<tbody className="w-full">
							{isLoading ? (
								<tr>
									<td align="center" colSpan={8}>
										<CircularProgress className="mt-20" size={35} />
									</td>
								</tr>
							) : (
								<>
									{student?.parents
										.concat(student?.approved_pickups || [])
										.concat(student?.extended_family || [])
										?.map((familie, index) => (
											<tr key={familie?.id} className="border-b">
												<td className="p-16 font-bold">
													<div className="flex items-center">
														<Avatar className="mr-4" src={familie?.thumb} />
														<div className="flex flex-col">
															<div className="pnstdform break-all truncate">
																{familie?.first_name} {familie?.last_name}
															</div>
															<div className="parent-relation break-all self-start truncate">
																{familie?.relation_with_child || '-'}
															</div>
															<div
																className="self-start truncate"
																style={{
																	color: familie.is_app_access_label_color,
																	fontSize: '10px',
																	fontWeight: '700',
																}}
															>
																{familie.is_app_access_label}
															</div>
														</div>
													</div>
												</td>
												<td className="p-16 break-all">
													<div>{familie?.email}</div>
												</td>
												<td className="p-16 break-all">
													<div>{familie?.phone}</div>
												</td>
												<td className="p-16">
													{familie?.emergency_contact === true ? 'Yes' : 'No'}
												</td>
												<td className="p-16">{familie?.can_pickup ? 'Yes' : 'No'}</td>
												<td className="p-16">
													<div className="flex items-baseline">
														<div>
															{hiddenCodes[index]
																? (familie?.parent_schools &&
																		familie?.parent_schools[0]?.checkin_code
																			?.split('')
																			?.map(() => '*')
																			?.join('')) ||
																  'N/A'
																: familie?.parent_schools &&
																  familie?.parent_schools[0]?.checkin_code}
														</div>
														{familie?.parent_schools ? (
															<IconButton
																onClick={() => handleCheckInCodeShowHide(index)}
															>
																{hiddenCodes[index] ? (
																	<VisibilityOffOutlined />
																) : (
																	<VisibilityOutlined />
																)}
															</IconButton>
														) : null}
														<div className="flex justify-center">
															{familie?.parent_schools && isAdmin ? (
																(familie?.parent_schools[0]?.checkin_code_request &&
																	((changeCodeLoader[index] && (
																		<div className="flex align-center justify-center">
																			<CircularProgress size={35} />
																		</div>
																	)) || (
																		<CustomButton
																			variant="primary"
																			type="submit"
																			width="140px"
																			height="30px"
																			fontSize="11px"
																			onClick={() =>
																				changeCode(familie?.id, index)
																			}
																		>
																			Change Checkin Code
																		</CustomButton>
																	))) || <span />
															) : (
																<span />
															)}
														</div>
													</div>
												</td>
												<td className="p-16" component="th" scope="row">
													<div className="flex items-end">
														<IconButton
															size="small"
															onClick={() =>
																goToAddEditContact(1, familie, student?.parents)
															}
														>
															<img
																src="assets/images/circle-edit.png"
																alt="edit"
																width="25px"
															/>
														</IconButton>
														{isAdmin && (
															<>
																{familie?.verified_at === null &&
																familie.is_primary_account !== 1 ? (
																	<IconButton
																		size="small"
																		onClick={() => handledelete(familie)}
																	>
																		<img
																			src="assets/images/dlt.png"
																			alt="edit"
																			width="25px"
																		/>
																	</IconButton>
																) : (
																	<></>
																)}
															</>
														)}
													</div>
												</td>
											</tr>
										))}
								</>
							)}
						</tbody>
					</table>
				</div>
			</div>
			<div className="contact-view-responsive flex justify-between mt-20 mb-80 pb-80 lg:mb-64 lg:pb-64">
				<CustomButton
					variant="primary"
					height="46"
					width="120px"
					fontSize="15px"
					onClick={() => setCurrentState('room-attachments')}
				>
					<div className="flex items-center justify-center">
						<ArrowBackIos className="" style={{ fontSize: '1.6rem' }} />
						Previous
					</div>
				</CustomButton>
				<CustomButton
					variant="primary"
					height="46"
					width="100px"
					fontSize="15px"
					onClick={handleNext}
					disabled={isLoading}
				>
					<div className="flex items-center justify-center ml-6">
						Next
						<ArrowForwardIos className="ml-6" style={{ fontSize: '1.6rem' }} />
					</div>
				</CustomButton>
			</div>
		</>
	);
}

export default ContactView;
