import { CircularProgress, Drawer, FormControl, makeStyles, MenuItem, Select, TextField } from '@material-ui/core';
import Check from '@material-ui/icons/Check';
import clsx from 'clsx';
import { useState } from 'react';

const useStyles = makeStyles({
	layoutRoot: {},
	select: {
		'&:before': {
			borderBottom: 'none',
		},
		'& .MuiSelect-select.MuiSelect-select': {
			textAlign: 'left',
		},
		'&:after': {
			borderBottom: 'none',
		},
		'&:not(.Mui-disabled):hover::before': {
			borderBottom: 'none',
		},
		'& .MuiSelect-select:focus': {
			backgroundColor: 'inherit',
		},
	},
	dateStyle: {
		'& .MuiInput-root::after': {
			borderBottom: 'none',
		},
		'& .MuiInput-underline::before': {
			borderBottom: 'none',
		},
		'& .MuiInput-underline:hover::before': {
			borderBottom: 'none',
		},
		'& .MuiInputBase-input': {
			cursor: 'pointer',
		},
		'& .MuiInput-root': {
			cursor: 'pointer',
		},
	},
	accordionSummaryStyle: {
		background: 'radial-gradient(circle, rgba(255, 255, 255, 1) 46%, rgba(235, 247, 254, 1) 68%)',
	},
	textField: {
		'& .MuiInput-underline:before': {
			borderBottom: 'none',
		},
		'& .MuiInput-underline:hover:before': {
			borderBottom: 'none',
		},
		'& .MuiInput-underline:after': {
			borderBottom: 'none',
		},
		'& .MuiInputBase-input': {
			cursor: 'pointer',
			borderRadius: '9999px',
			border: 'solid 1px  #5B81F0',
			paddingInline: '10px',
			marginTop: '-10px',
		},
		'& .MuiInputLabel-root': {
			paddingLeft: '12px',
			marginTop: '-10px',
		},
		'& label.Mui-focused': {
			color: '#5E6368',
			borderColor: '#5E6368',
			transform: 'translate(0px, 24px) ',
		},
		'& label.MuiFormLabel-filled': {
			transform: 'translate(0px, 24px) ',
			display: 'none',
		},

		'& .MuiInputLabel-formControl': {
			transform: 'translate(0px, 24px) ',
		},

		'& .MuiOutlinedInput-root': {
			background: '#F9F9F9',
			color: '#5E6368',
			paddingBlock: '2.5px',
			'&.Mui-focused': {
				background: '#F1F5FE',
			},
			'&.Mui-focused fieldset ': {
				borderColor: '#3339FF',
				borderWidth: '1px',
			},
			borderColor: '#5E6368',
			borderRadius: '10px',
			'&.Mui-error': {
				background: '#FEF1F1',
				borderColor: '#EF2C2C',
			},

			'& fieldset': {
				top: 0,
			},
			'& legend': {
				display: 'none',
			},
		},
	},
});

const LiveStreamingDrawer = ({
	selectedRoomId,
	open,
	setOpen,
	showFixedDrawer,
	rooms,
	isLoading,
	handleRoomChange,
}) => {
	const [activeFilter, setActiveFilter] = useState('all');
	const [searchText, setSearchText] = useState('');
	const classes = useStyles();
	const handleToggleDrawer = () => {
		setOpen(!open);
	};

	const handleFilterChange = (e) => {
		setActiveFilter(e.target.value);
	};

	const handleSearchTextChange = (e) => {
		setSearchText(e.target.value);
	};

	const filteredRooms = rooms
		.filter((room) => {
			if (activeFilter === 'all') return true;
			if (activeFilter === 'active') return room.is_active === 1;
			if (activeFilter === 'inactive') return room.is_active === 0;
			return true;
		})
		.filter((room) => room.name.toLowerCase().includes(searchText.toLowerCase()));

	return (
		<Drawer
			variant={showFixedDrawer ? 'permanent' : 'temporary'}
			anchor="right"
			classes={{
				paper: showFixedDrawer ? 'pt-160 px-16 ' : 'px-16 pt-32 ',
			}}
			PaperProps={{
				style: {
					width: '300px',
					background: 'linear-gradient(130deg, rgba(244, 249, 255, 1) 0%, rgba(208, 226, 244, 1) 100%)',
				},
			}}
			open={open}
			onClose={handleToggleDrawer}
		>
			<div className="mb-32 border-b pb-32 flex flex-col gap-16">
				<div>
					<h2 className="font-bold mb-16" style={{ fontSize: '18px' }}>
						Select Room
					</h2>
					<p>Filter</p>
					<div className="">
						<TextField
							label="Room name"
							fullWidth
							value={searchText}
							onChange={handleSearchTextChange}
							className={classes.textField}
						/>
					</div>
				</div>
			</div>

			<div className={clsx(classes.divider)} />

			{isLoading ? (
				<div className="flex justify-center mt-16">
					<CircularProgress size={35} />
				</div>
			) : (
				<div className=" w-256 flex flex-col gap-16">
					{filteredRooms.map((room) => {
						const isSelected = selectedRoomId === room.id;
						return (
							<button
								className={clsx(
									'flex items-center gap-16 border border-gray-200 rounded-lg px-16 py-8 shadow-lg',
									isSelected && 'bg-pd-blue'
								)}
								key={room.id}
								onClick={(e) => handleRoomChange(e, room)}
								style={{
									background:
										'linear-gradient(270deg, rgba(255, 255, 255, 1) 46%, rgba(235, 247, 254, 1) 68%)',
								}}
							>
								<img src={room.thumb} alt={room.name} className="w-44" />
								<span className="font-bold">{room.name}</span>
								<div
									className="text-white flex items-center justify-center border-1 border-pd-blue rounded-full h-20 text-center w-20 ml-auto"
									style={{
										border: '1px #4da0ee solid',
										background: isSelected ? '#4DA0EE' : 'white',
										justifySelf: 'flex-end',
									}}
								>
									<Check className="w-16" />
								</div>
							</button>
						);
					})}
				</div>
			)}
		</Drawer>
	);
};

export default LiveStreamingDrawer;
