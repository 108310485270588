const CheckinsIconSmall = (props) => (
	<svg xmlns="http://www.w3.org/2000/svg" width={25} height={24} fill="none" viewBox="0 0 198 199" {...props}>
		<path
			fill="#757575"
			// fillOpacity={0.54}
			d="M131.141 66.87h-23.234c-5.397 0-8.746-2.933-8.684-7.28.063-4.348 3.38-7.176 8.83-7.187h46.883c5.678 0 8.954 2.87 8.84 7.572-.114 4.45-3.266 6.916-8.986 6.936-7.883-.02-15.766-.041-23.649-.041Zm-75.442 33.353c5.658-5.71 10.483-10.64 15.371-15.517 4.067-4.046 8.476-4.441 11.607-1.124 3.13 3.318 2.527 7.374-1.383 11.316a3817.539 3817.539 0 0 1-19.074 19.084 7.579 7.579 0 0 1-9.824 2.377 7.584 7.584 0 0 1-2.708-2.45c-4.16-4.077-8.32-8.164-12.314-12.334a7.452 7.452 0 0 1-2.92-5.299 7.437 7.437 0 0 1 1.984-5.715c3.12-3.214 7.384-2.912 11.274 1.04 2.631 2.569 5.054 5.408 7.987 8.622Zm.104 43.17c5.814-5.918 10.858-11.222 16.11-16.318a6.94 6.94 0 0 1 10.24-.803 6.932 6.932 0 0 1 1.949 3.268 9.652 9.652 0 0 1-1.976 7.447c-6.636 7.28-13.697 14.081-20.738 20.935a7.037 7.037 0 0 1-8.297 1.823 7.042 7.042 0 0 1-2.405-1.823c-4.7-4.486-9.294-9.09-13.78-13.811a7.287 7.287 0 0 1-2.459-5.301 7.277 7.277 0 0 1 2.23-5.401 7.009 7.009 0 0 1 10.4.572c2.83 2.829 5.492 5.886 8.726 9.412Zm-.041-86.34c5.813-5.898 10.868-11.191 16.11-16.277A6.926 6.926 0 0 1 84.05 43.19a9.589 9.589 0 0 1-1.914 7.446c-6.625 7.28-13.686 14.082-20.727 20.925a7.144 7.144 0 0 1-11.024-.135 478.096 478.096 0 0 1-13.52-13.52 7.208 7.208 0 1 1 10.13-10.213c2.911 2.798 5.522 5.855 8.767 9.36Zm75.597 52.894H107.71c-5.2 0-8.435-2.788-8.487-7.041-.052-4.483 3.235-7.353 8.622-7.364h47.299c5.45 0 8.663 2.777 8.653 7.281-.011 4.503-3.193 7.113-8.788 7.124h-23.65Zm.208 43.149h-24.065c-5.2 0-8.32-2.87-8.32-7.186s3.244-7.114 8.403-7.124h47.705c5.2 0 8.32 2.683 8.455 7.061.104 4.545-3.047 7.28-8.58 7.28h-23.65l.052-.031Z"
		/>
	
	</svg>
);
export default CheckinsIconSmall;
