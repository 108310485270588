export const calculateRatio = (room) => {
	const studentRatio = room.student_ratio_count || 2; // Default to 2 if not set
	const staffRatio = room.required_staff || 1; // Default to 1 if not set

	// get the greatest common divisor
	const gcd = (a, b) => (b === 0 ? a : gcd(b, a % b));
	const divisor = gcd(studentRatio, staffRatio);

	return {
		students: studentRatio / divisor,
		staff: staffRatio / divisor,
	};
};