import * as Actions from '@/app/store/actions';
import { useEffect, useState } from 'react';
import './SubAdminDashboard.css';
import { getreminders } from '@/app/services/childreminder/childreminder';
import {
	Avatar,
	CircularProgress,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
} from '@material-ui/core';
import dayjs from 'dayjs';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useDispatch } from 'react-redux';
import DashboardCard from '@/app/fuse-layouts/layout1/components/Dashboard/DashboardCard';

function ChildReminder() {
	const dispatch = useDispatch();
	const [viewAll, setViewAll] = useState(false);
	const [hasMore, setHasMore] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [rows, setRows] = useState([]);
	const [page, setPage] = useState(1);

	useEffect(() => {
		let isMounted = true;
		setIsLoading(true);
		getreminders(page)
			.then((res) => {
				if (!isMounted) return;
				setIsLoading(false);
				setHasMore(true);
				setRows([...rows, ...res.data.data]);
				if (res.data.current_page < res.data.last_page) {
					setHasMore(true);
					setPage(res.data.current_page + 1);
				} else {
					setHasMore(false);
				}
			})
			.catch((err) => {
				if (!isMounted) return;
				dispatch(
					Actions.showMessage({
						message: 'Failed to fetch data, please refresh',
						variant: 'error',
					})
				);
			})
			.finally(() => {
				if (!isMounted) return;
				setIsLoading(false);
			});

		return () => {
			isMounted = false;
		};
	}, []);

	const handleLoadMore = () => {
		setIsLoading(true);
		getreminders(page)
			.then((res) => {
				if (res.data.last_page > res.data.current_page) {
					setHasMore(true);
				} else {
					setHasMore(false);
				}
				setPage(res.data.current_page + 1);
				setRows(rows.concat(res.data.data));
				setIsLoading(false);
			})
			.catch((err) => {
				setIsLoading(false);
				dispatch(
					Actions.showMessage({
						message: 'Failed to fetch data, please refresh',
						variant: 'error',
					})
				);
			});
	};
	const handleViewAll = () => {
		setViewAll(true);
	};

	return (
		<>
			<DashboardCard showViewMore={rows.length > 5} onViewMore={handleViewAll} title="Child Reminders">
				<TableContainer id="Scrollable-table" className="">
					<Table stickyHeader className="student-table" style={{ width: '100%' }}>
						<TableHead>
							<TableRow>
								<TableCell style={{ width: '25%' }} className="bg-white studentTableHeader">
									Child
								</TableCell>
								<TableCell style={{ width: '25%' }} className="bg-white studentTableHeader">
									Type
								</TableCell>
								<TableCell style={{ width: '25%' }} className="bg-white studentTableHeader">
									Details
								</TableCell>
								<TableCell
									align="right"
									style={{ width: '25%' }}
									className="bg-white studentTableHeader"
								>
									Due date
								</TableCell>
							</TableRow>
						</TableHead>
						{isLoading ? (
							<TableBody>
								<TableRow>
									<TableCell align="center" colSpan={8} className="bg-white">
										<CircularProgress size={35} />
									</TableCell>
								</TableRow>
							</TableBody>
						) : !rows.length ? (
							<TableBody>
								<TableRow>
									<TableCell align="center" colSpan={8} className="bg-white no-staffs">
										No Reminders
									</TableCell>
								</TableRow>
							</TableBody>
						) : (
							<TableBody>
								{rows.slice(0, viewAll ? rows.length : 5).map((rows, key) => {
									return (
										<TableRow key={rows.id}>
											<TableCell className="bg-white truncate reminder-columns">
												<div className="flex flex-col">
													<div className="flex">
														<Avatar
															className="mr-6"
															src={rows.student.photo}
														/>
														<div className="flex  items-center justify-content: center">
															<div className="report-std truncate width-name">
																{rows.student.first_name} {rows.student.last_name}
															</div>
														</div>
													</div>
												</div>
											</TableCell>
											<TableCell className="bg-white truncate reminder-columns">
												{rows.category === 'Medical Form' ? (
													<div className="flex items-center"> Medical Form</div>
												) : rows.category === 'EpiPen Form' ? (
													<div className="flex items-center">EpiPen Form</div>
												) : rows.category === 'Other' ? (
													<div className="flex items-center"> Other</div>
												) : (
													<div className="empty-cell reminder-columns">-</div>
												)}
											</TableCell>
											<TableCell align="center" className="bg-white truncate reminder-columns">
												{rows.category}
											</TableCell>
											<TableCell className="bg-white truncate reminder-columns">
												<div className="flex  items-center ">
													{rows?.expiry_date ? (
														<>
															{dayjs(`${rows?.expiry_date}z`).format(
																'MMMM[ ] D[,] YYYY [ ]'
															)}
														</>
													) : (
														'-'
													)}
												</div>
											</TableCell>
										</TableRow>
									);
								})}
							</TableBody>
						)}
					</Table>
				</TableContainer>
				{viewAll && (
					<InfiniteScroll
						dataLength={rows.length}
						next={handleLoadMore}
						hasMore={hasMore}
						scrollableTarget="Scrollable-table"
					/>
				)}
			</DashboardCard>
		</>
	);
}

export default ChildReminder;
