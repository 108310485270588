import React from 'react';
import authRoles from '../../../auth/authRoles';
import StaffScheduleListing from './StaffScheduleListing';
import workshiftSchedules from './workshiftSchedules';
import ptoSchedules from './ptoSchedules';
import sickSchedules from './sickSchedules';
import editWorkShiftSchedule from './editWorkShiftSchedule';
import editPtoSchedule from './editPtoSchedule';
import editSickSchedule from './editSickSchedule';

const StaffConfig = {
	settings: {
		layout: {
			config: {},
		},
	},
	routes: [
		{
			path: '/staff-schedule',
			// component: StaffScheduleListing
			component: React.lazy(() => import('./StaffScheduleListing')),
		},
		{
			path: '/staff-schedule-workshiftschedule',
			// component: workshiftSchedules
			component: React.lazy(() => import('./workshiftSchedules')),
		},
		{
			path: '/staff-schedule-ptoschedule',
			// component: ptoSchedules
			component: React.lazy(() => import('./ptoSchedules')),
		},
		{
			path: '/staff-schedule-sickschedule',
			// component: sickSchedules
			component: React.lazy(() => import('./sickSchedules')),
		},
		{
			path: '/staff-schedule-editwork-shiftschedule',
			// component: editWorkShiftSchedule
			component: React.lazy(() => import('./editWorkShiftSchedule')),
		},
		{
			path: '/staff-schedule-editptoschedule',
			// component: editPtoSchedule
			component: React.lazy(() => import('./editPtoSchedule')),
		},
		{
			path: '/staff-schedule-editsickschedule',
			// component: editSickSchedule
			component: React.lazy(() => import('./editSickSchedule')),
		},
	],
	auth: authRoles.subadmin,
};

export default StaffConfig;
