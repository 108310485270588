import CustomButton from '@/app/customComponents/CustomButton/CustomButton';
import { IconButton, Paper } from '@material-ui/core';
import { ChevronRight } from '@material-ui/icons';
import React from 'react';

const DashboardCard = ({ showViewMore = false, onViewMore, title, children }) => {
	return (
		<div className="flex flex-col gap-25">
			<div className="flex justify-between items-center ">
				<h2 className="text-2" style={{ fontWeight: '700' }}>
					{title}
				</h2>
				{showViewMore && (
					<button className="flex text-16 text-pd-blue-light hover:opacity-75 items-center" onClick={onViewMore}>
						View all <ChevronRight />
					</button>
				)}
			</div>
			<Paper className="rounded-8 shadow-card p-25 w-full">{children}</Paper>
		</div>
	);
};

export default DashboardCard;
