import FuseSplashScreen from '@/@fuse/core/FuseSplashScreen';
import * as userActions from '@/app/auth/store/actions';
import jwtService from '@/app/services/jwtService';
import { sendFCMTokenToBackend } from '@/app/services/notifications/fcmServices';
import { app } from '@/utils/utils';
import { getMessaging, getToken } from 'firebase/messaging';
import * as Actions from '@/app/store/actions';
import axios from 'axios';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import secureLocalStorage from 'react-secure-storage';

const messaging = getMessaging(app);

class Auth extends Component {
	state = {
		waitAuthCheck: true,
	};
	/**
	 * The function `registerWithFirebase()` registers the device with Firebase Cloud Messaging (FCM) by
	 * retrieving a device token and sending it to the backend server.
	 */
	registerWithFirebase() {
		getToken(messaging, {
			vapidKey: import.meta.env.VITE_FIREBASE_VAPID_KEY,
		})
			.then((deviceToken) => {
				if (deviceToken) {
					sendFCMTokenToBackend(deviceToken);
				} else {
					// Show permission request UI
					console.log('No registration token available. Request permission to generate one.');
					// ...
				}
			})
			.catch((err) => {
				console.log('An error occurred while retrieving token. ', err);
				// ...
			});
	}
	/**
	 * The componentDidMount function checks if the user is authenticated and performs various actions
	 * based on the user's role.
	 * @returns The `componentDidMount()` function returns a Promise.
	 */
	componentDidMount() {
		/**
		 * if user does not exist in localstorage, do a jwt check, if it resolves, register with firebase
		 */
		if (!JSON.parse(secureLocalStorage.getItem('user'))) {
			return Promise.all([
				// Comment the lines which you do not use
				// this.firebaseCheck(),
				// this.auth0Check(),
				this.jwtCheck(),
			])
				.then(() => {
					this.setState({ waitAuthCheck: false });
					this.registerWithFirebase();
				})
				.catch(() => {
					this.setState({ waitAuthCheck: false });
				});
		}
		/**
		 * if user exists in localstorage, check if token is 5 characters long, if it is, set it as it is, else add Bearer
		 */
		const token = secureLocalStorage.getItem('jwt_access_token');
		if (token.length === 5) {
			axios.defaults.headers.common.Authorization = token;
		} else {
			axios.defaults.headers.common.Authorization = `Bearer ${token}`;
		}
		/**
		 * check if the token is valid, if it is, register with firebase, and set the user data
		 */
		jwtService
			.getProfile()
			.then(() => {
				const currentUser = JSON.parse(secureLocalStorage.getItem('user'));
				if (currentUser.role[0] !== 'super_admin') {
					this.registerWithFirebase();
				}
				if (currentUser.role[0] === 'super_school_admin') {
					axios
						.get('/api/v2/profile')
						.then((res) => {
							const user = {
								...currentUser,
								school: res.data.school,
								doNotRedirect: 1,
							};
							this.props.setUserData(user);
							this.setState({ waitAuthCheck: false });
						})
						.catch(() => {
							this.props.logout();
							const user = {
								role: [],
								data: {
									displayName: 'John Doe',
									photoURL: '',
									email: 'johndoe@withinpixels.com',
									shortcuts: ['calendar', 'mail', 'contacts', 'todo'],
								},
							};
							this.props.setUserData(user);
							this.setState({ waitAuthCheck: false });
						});
				} else {
					this.props.setUserData({ ...currentUser, doNotRedirect: 1 });
					this.setState({ waitAuthCheck: false });
				}
				return 1;
			})
			.catch(() => {
				this.setState({ waitAuthCheck: false });
			});
	}

	/* The `jwtCheck` function is a promise that handles the authentication process using JSON Web Tokens
(JWT). Hit profile API with the token from localstorage, if response is 200, log in the user. */
	jwtCheck = () =>
		new Promise((resolve, reject) => {
			jwtService.on('onAutoLogin', () => {
				// this.props.showMessage({ message: 'Logging in with JWT', autoHideDuration: 1000, variant: 'info' });

				/**
				 * Sign in and retrieve user data from Api
				 */
				jwtService
					.signInWithToken()
					.then((user) => {
						this.props.setUserData(user);

						resolve();

						// this.props.showMessage({ message: 'Logged In', autoHideDuration: 1000, variant: 'success' });
					})
					.catch((error) => {
						this.props.showMessage({ message: error });

						reject();
					});
			});

			jwtService.on('onAutoLogout', (message) => {
				if (message) {
					this.props.showMessage({ message, variant: 'error' });
				}

				this.props.logout();
				this.props.removeUserData();
				reject();
			});

			jwtService.on('onNoAccessToken', () => {
				reject();
			});

			jwtService.init();

			return Promise.resolve();
		});

	/**
	 * The render function conditionally renders either a loading screen or the children components based
	 * on the value of the waitAuthCheck state variable.
	 * @returns The render method is returning either the `<FuseSplashScreen />` component if
	 * `this.state.waitAuthCheck` is true, or the `this.props.children` if `this.state.waitAuthCheck` is
	 * false.
	 */
	render() {
		return this.state.waitAuthCheck ? <FuseSplashScreen /> : <>{this.props.children}</>;
	}
}

/**
 * The function mapDispatchToProps binds action creators to the dispatch function, allowing them to be
 * called and dispatched to the Redux store.
 * @returns The function `mapDispatchToProps` is returning an object that contains various action
 * creators bound to the `dispatch` function using the `bindActionCreators` function.
 */
function mapDispatchToProps(dispatch) {
	return bindActionCreators(
		{
			logout: userActions.logoutUser,
			setUserData: userActions.setUserData,
			setUserDataAuth0: userActions.setUserDataAuth0,
			setUserDataFirebase: userActions.setUserDataFirebase,
			showMessage: Actions.showMessage,
			hideMessage: Actions.hideMessage,
			removeUserData: userActions.removeUserData,
		},
		dispatch
	);
}

export default connect(null, mapDispatchToProps)(Auth);
