import MomentUtils from '@date-io/moment';
import FuseAuthorization from '@/@fuse/core/FuseAuthorization';
import FuseLayout from '@/@fuse/core/FuseLayout';
import FuseTheme from '@/@fuse/core/FuseTheme';
import history from '@/@history';
import { createGenerateClassName, jssPreset, StylesProvider } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { create } from 'jss';
import jssExtend from 'jss-plugin-extend';
import rtl from 'jss-rtl';
import React, { useEffect } from 'react';
import Provider from 'react-redux/es/components/Provider';
import { Router } from 'react-router-dom';
import AppContext from './AppContext';
import { Auth } from './auth';
import routes from './fuse-configs/routesConfig';
import store from './store';
import History from '@/@history/@history';
import markerSDK from '@marker.io/browser';
import './App.css';
import PwaContextProvider from './context/PwaContext/PwaContextProvider';

/* The code is creating an instance of JSS (JavaScript Style Sheets) with some preset configurations. */
const jss = create({
	...jssPreset(),
	plugins: [...jssPreset().plugins, jssExtend(), rtl()],
	insertionPoint: document.getElementById('jss-insertion-point'),
});

const generateClassName = createGenerateClassName();

// disable logging in production
if (import.meta.env.VITE_ENV === 'production') {
	console.log = () => {};
}

const injectMarkerSdk = async () => {
	if (import.meta.env.VITE_MARKER_IO_PROJECT_ID) {
		await markerSDK.loadWidget({
			project: import.meta.env.VITE_MARKER_IO_PROJECT_ID,
		});
	}
};

injectMarkerSdk();

const App = () => {
	useEffect(() => {
		/**
		 * The function `handleInvalidToken` clears the localStorage and redirects the user to the login page
		 * when local storage is cleared on another tab. (Local Storage is cleared when the user logs out)
		 */
		const handleInvalidToken = (e) => {
			if (!e.key && !e.oldValue && !e.newValue) {
				localStorage.clear();
				History.push('/login');
			}
		};
		window.addEventListener('storage', handleInvalidToken);
		return function cleanup() {
			window.removeEventListener('storage', handleInvalidToken);
		};
	}, []);
	return (
		<AppContext.Provider
			value={{
				routes,
			}}
		>
			<StylesProvider jss={jss} generateClassName={generateClassName}>
				<Provider store={store}>
					<MuiPickersUtilsProvider utils={MomentUtils}>
						<Auth>
							<Router history={history}>
								<FuseAuthorization>
									<FuseTheme>
										<PwaContextProvider>
											<FuseLayout />
										</PwaContextProvider>
									</FuseTheme>
								</FuseAuthorization>
							</Router>
						</Auth>
					</MuiPickersUtilsProvider>
				</Provider>
			</StylesProvider>
		</AppContext.Provider>
	);
};

export default App;
