import { PublicClientApplication } from '@azure/msal-browser';
const baseUrl = 'https://onedrive.live.com/picker';

// the options we pass to the picker page through the querystring

let app = null;
let redirectUris = {
	dev: 'https://dev.perfectdaylive.com',
	qa: 'https://qa.perfectdaylive.com',
	stage: 'https://stage.perfectdaylive.com',
	production: 'https://admin.perfectdaylive.com',
};
const getAppInstance = () => {
	const msalParams = {
		auth: {
			authority: 'https://login.microsoftonline.com/consumers',
			clientId: import.meta.env.VITE_AZURE_CLIENT_ID,
			redirectUri: redirectUris[import.meta.env.VITE_ENV],
		},
	};
	if (!app) {
		app = new PublicClientApplication(msalParams);
	}
	return app;
};

const params = {
	sdk: '8.0',
	entry: {
		oneDrive: {
			files: {},
		},
	},
	authentication: {},
	messaging: {
		origin: 'http://localhost:3000',
		channelId: '27',
	},
	typesAndSources: {
		mode: 'files',
		filters: ['xlsx', 'xls'],
		pivots: {
			oneDrive: true,
			recent: true,
		},
	},
};

let win = null;
let port = null;

export default async function launchPicker(e) {
	const authToken = await getToken();
	if (!authToken) {
		return;
	}
	win = window.open('', 'Picker', 'width=800,height=600');

	const queryString = new URLSearchParams({
		filePicker: JSON.stringify(params),
	});

	const url = `${baseUrl}?${queryString}`;

	const form = win.document.createElement('form');
	form.setAttribute('action', url);
	form.setAttribute('method', 'POST');
	win.document.body.append(form);

	const input = win.document.createElement('input');
	input.setAttribute('type', 'hidden');
	input.setAttribute('name', 'access_token');
	input.setAttribute('value', authToken);
	form.appendChild(input);

	form.submit();

	window.addEventListener('message', (event) => {
		if (event.source && event.source === win) {
			const message = event.data;

			if (message.type === 'initialize' && message.channelId === params.messaging.channelId) {
				port = event.ports[0];

				port.addEventListener('message', messageListener);

				port.start();

				port.postMessage({
					type: 'activate',
				});
			}
		}
	});
}

async function messageListener(message) {
	console.log(message);
	switch (message.data.type) {
		case 'notification':
			console.log(`notification: ${message.data}`);
			break;

		case 'command':
			port.postMessage({
				type: 'acknowledge',
				id: message.data.id,
			});
			const command = message.data.data;
			switch (command.command) {
				case 'authenticate':
					// getToken is from scripts/auth.js
					const token = await getToken();

					if (typeof token !== 'undefined' && token !== null) {
						port.postMessage({
							type: 'result',
							id: message.data.id,
							data: {
								result: 'token',
								token,
							},
						});
					} else {
						console.error(`Could not get auth token for command: ${JSON.stringify(command)}`);
					}

					break;

				case 'close':
					win.close();
					break;

				case 'pick':
					console.log(`Picked: ${JSON.stringify(command)}`);
					// document.getElementById('pickedFiles').innerHTML = `<pre>${JSON.stringify(command, null, 2)}</pre>`;
					// download the picked file
					const validExtensions = window.oneDriveRosterType === 'procare' ? ['csv'] : ['xlsx', 'xls'];
					if (!validExtensions.includes(command?.items[0]?.name?.split('.')?.at(-1)?.toLowerCase())) {
						window.invalidFilePickedOneDrive();
						return;
					}
					const file = command.items[0];
					const url = `${file['@sharePoint.endpoint']}/drives/${file.parentReference.driveId}/items/${file.id}`;
					const resp = await fetch(url, {
						headers: {
							Authorization: `Bearer ${await getToken(true)}`,
						},
					});
					const jsonResponse = await resp.json();
					const fileContent = await fetch(jsonResponse['@content.downloadUrl']);
					const blob = await fileContent.blob();
					window.openValidateOneDrive(blob);

					port.postMessage({
						type: 'result',
						id: message.data.id,
						data: {
							result: 'success',
						},
					});

					win.close();

					break;

				default:
					console.warn(`Unsupported command: ${JSON.stringify(command)}`, 2);

					port.postMessage({
						result: 'error',
						error: {
							code: 'unsupportedCommand',
							message: command.command,
						},
						isExpected: true,
					});
					break;
			}

			break;
	}
}

async function getToken(readFile = false) {
	let accessToken = '';
	const app = getAppInstance();
	const authParams = readFile ? { scopes: ['Files.Read'] } : { scopes: ['OneDrive.ReadWrite'] };

	try {
		// see if we have already the idtoken saved
		const resp = await app.acquireTokenSilent(authParams);
		console.log(resp);
		accessToken = resp.accessToken;
	} catch (e) {
		// per examples we fall back to popup
		const resp = await app.loginPopup(authParams);
		console.log(resp);
		app.setActiveAccount(resp.account);

		if (resp.idToken) {
			const resp2 = await app.acquireTokenSilent(authParams);
			accessToken = resp2.accessToken;
		}
	}

	return accessToken;
}
