import FuseAnimate from '@/@fuse/core/FuseAnimate';
import FusePageSimple from '@/@fuse/core/FusePageSimple';
import { makeStyles } from '@material-ui/core/styles';
import { useRef } from 'react';
import ChildReminder from './Childremainder';
import EventsSidebar from './EventsSideBar';
import LoggedActivities from './LoggedActivities/LoggedActivities';
import MessageListing from './MessageListing';
import RoomRatios from './RoomRatios/RoomRatios';
import './SubAdminDashboard.css';
import UpcomingBirthdays from './UpcomingBirthday';

const useStyles = makeStyles({
	layoutRoot: {},
	sidebar: {
		width: 320,
	},
});

function SubAdminDashboard() {
	const classes = useStyles();
	const pageLayout = useRef(null);

	return (
		<FusePageSimple
			classes={{
				root: classes.layoutRoot,
				sidebar: classes.sidebar,
			}}
			content={
				<div className="px-24 xl:px-64 py-68 mb-64  max-w-4xl mx-auto">
					<FuseAnimate animation="transition.slideLeftIn" duration={600}>
						<div className="">
							
								<div className="flex flex-col gap-32">
									<div className="flex flex-col md:flex-row gap-32 md:gap-20">
										<div className="flex-1 md:max-w-1/2">
											<MessageListing />
										</div>
										<div className="flex-1 md:max-w-1/2">
											<RoomRatios />
										</div>
									</div>
									<ChildReminder />
									<LoggedActivities />
								</div>
				
						</div>
					</FuseAnimate>
				</div>
			}
			rightSidebarContent={
				<div className="home-sidebar">
					<EventsSidebar />
					<UpcomingBirthdays />
				</div>
			}
			innerScroll
			ref={pageLayout}
		/>
	);
}

export default SubAdminDashboard;
