/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { makeStyles } from '@material-ui/core/styles';
import dayjs from 'dayjs';
import React, { useRef, useState } from 'react';
import { TimePicker as AntDTimePicker } from 'antd';
import './CustomTime.css';

const useStyles = makeStyles({
	root: {
		'& .MuiFormHelperText-root': {
			color: 'red',
		},
	},
});

const format = 'hh:mm A';

export default function TimePicker({
	handleTimeChange,
	name,
	width = undefined,
	disabled = false,
	label,
	errTxts,
	value,
	setValue,
}) {
	const classes = useStyles();
	const ref = useRef(null);
	console.log(value);
	return (
		<div className="mt-12">
			<div className="flex flex-col justify-end placeholder_css_only">
				<AntDTimePicker
					className="placeholder_field_custom_time"
					allowClear={false}
					disabled={disabled}
					showNow={false}
					style={{
						border: 0,
						borderBottom: `1px solid ${errTxts[name] ? 'red' : '#121212'}`,
						borderRadius: 0,
						width,
						boxShadow: 'none',
					}}
					defaultValue={value ? dayjs(value) : undefined}
					use12Hours
					onChange={(e) => {
						handleTimeChange(e.format('HH:mm'), name);
						setValue(e);
					}}
					format={format}
					status={errTxts[name] ? 'error' : ''}
					placeholder="--:-- --"
				/>
			</div>
			<div className="text-xs text-red mt-2">{errTxts[name]}</div>
		</div>
	);
}
