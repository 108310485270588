import { Tab, withStyles } from "@material-ui/core";

export const StyledTab = withStyles((theme) => ({
	root: {
		textTransform: 'none',
		color: 'black',
		fontWeight: 600,
		marginRight: theme.spacing(1),
		opacity: 1,
	},
}))((props) => <Tab disableRipple {...props} />);
