import React from 'react';
import { Avatar, CircularProgress, Divider } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import * as Actions from '@/app/store/actions';
import { checkIn, checkOut } from '@/app/services/students/students';
import CustomButton from '@/app/customComponents/CustomButton/CustomButton';

function PickupCard({
	pickup,
	checkedIn,
	studentId,
	roomId,
	isLoading,
	setActiveId,
	setIsLoading,
	setOpen,
	setRefresh,
}) {
	const dispatch = useDispatch();

	const openImage = () => {
		dispatch(
			Actions.openDialog({
				children: (
					<>
						<div className="bg-white">
							<img src={pickup.photo} alt="" />
						</div>
					</>
				),
			})
		);
	};

	const handleCheckInOut = () => {
		setIsLoading(true);
		const data = {
			student_id: [studentId],
			room_id: roomId,
			family_id: pickup.id,
			is_parent: pickup.role === 'parent' ? 1 : 0,
		};
		if (!checkedIn) {
			checkIn(data)
				.then((res) => {
					setActiveId(0);
					setOpen(false);
					setRefresh(true);
					dispatch(
						Actions.showMessage({
							variant: 'success',
							message: res.data.message,
						})
					);
				})
				.catch((err) => {
					dispatch(
						Actions.showMessage({
							variant: 'error',
							message: 'Failed to update student status',
						})
					);
				})
				.finally(() => setIsLoading(false));
		} else {
			checkOut(data)
				.then((res) => {
					setOpen(false);
					setRefresh(true);
					setActiveId(0);
					dispatch(
						Actions.showMessage({
							variant: 'success',
							message: res.data.message,
						})
					);
				})
				.catch((err) => {
					dispatch(
						Actions.showMessage({
							message: err.response?.data?.message,
						})
					);
				})
				.finally(() => setIsLoading(false));
		}
	};

	return (
		<div className="my-10 bg-white/70 rounded-8 p-16">
			<div className="flex flex-col gap-10">
				<div className="flex items-center gap-10">
					<Avatar
						style={{ height: '45px', width: '45px', cursor: 'pointer' }}
						className="pickup-profile-img mr-4"
						onClick={() => openImage(pickup.photo)}
						src={pickup.photo}
					/>
					<div>
						<h5 className="font-extrabold">{`${pickup.first_name} ${pickup.last_name}`}</h5>
						<span className="font-bold text-pd-blue">{pickup.relation_with_child}</span>
					</div>
				</div>

				<p className="pickup-card-email">{pickup.email}</p>
				<Divider />
				<div>
					<span>
						<i className="fa fa-phone rotate-90 transform" aria-hidden="true" />
					</span>
					<span> {pickup.phone}</span>
				</div>
				<div className="flex">
					{!isLoading ? (
						<CustomButton onClick={handleCheckInOut}>{checkedIn ? 'Check Out' : 'Check In'}</CustomButton>
					) : (
						<div>
							<CircularProgress size={25} />
						</div>
					)}
				</div>
			</div>
		</div>
	);
}

export default PickupCard;
